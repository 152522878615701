.b-solid
	border: 1px solid var(--color-blue)
	@media screen and (max-width: $md)
		border: 1px solid var(--color-blue)
.bt-solid
	border-top: 1px solid var(--color-border-solid)

.br-solid
	border-right: 1px solid var(--color-border-solid)

.bb-solid
	border-bottom: 1px solid var(--color-yellow)

.bl-solid
	border-left: 1px solid var(--color-border-solid)
