.of-cover
	object-fit: cover
	width: 100%
	height: 100%
.of-contain
	object-fit: contain
	width: 100%
	height: 100%
.full-height
	position: relative !important
	display: inline-block !important
	align-items: center !important
	justify-content: center !important
	width: auto !important
	height: 100% !important
	max-width: unset !important
.hs-full-height
	width: 100%
	height: auto
.auto-width-height
	width: auto
	height: auto
	max-height: 100%
	max-width: 100%
.full-width
	width: 100% !important
	height: auto !important
.of-cover-absolute
	object-fit: cover
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%