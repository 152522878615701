.media__link
	&:hover
		*
			color: currentColor
.media__caption
	margin-top: 1em

+max-screen-md
	.media__caption
		margin-bottom: 0.5em
