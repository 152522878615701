.masthead-2__background
	position: relative
	max-height: 100%
	height: 100%
	max-width: 100%
	overflow: hidden
.masthead-2__wrapper-background
	height: 100vh
.masthead-2__header_absolute
	position: absolute
	top: 0
	left: 0
.masthead-2__header
	position: relative
	z-index: 60

+max-screen-md
	.masthead-2__wrapper-background
		height: 66vh

+min-screen-md
	.masthead-2__header
		@include fluid('margin-left', var(--distance-min-xlarge), var(--distance-max-xlarge), $min_width, $max_width, -1)
