.split-counter
	display: inline-flex
	align-items: flex-end
.split-counter__lane
	position: relative
	overflow: hidden
	display: inline-block
	line-height: 1
	&:nth-child(1)
		span
			right: 0
	&:nth-child(2)
		span
			left: 0
	&:nth-child(3)
		span
			left: 0
