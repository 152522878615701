\:root
	--fix-1: 20px
	--fix-2: 25px


.svg-circle	
	.fix-svg-1
		padding-left: var(--fix-1)
		@media screen and (max-width: $sm)
			padding-left: 0
		u
			//left: calc( -1 * var(--fix-1)  )
			//@media screen and (max-width: $sm)
				//left: auto !important
	.fix-svg-2	
		position: relative
		left: var(--fix-1)
		@media screen and (max-width: $sm)
				left: auto !important
	.fix-svg-3
		@media screen and (max-width: $sm)
				left: calc( -1 * var(--fix-1) / 2  )
	
				
		
	p
		padding-left: var(--fix-1)
		@media screen and (max-width: $sm)
			padding-left: 0
	u
		padding: var(--fix-1)
		//@media screen and (max-width: $sm)
			//left: calc( -1 * var(--fix-1)  )
	.fix-sm
		@media screen and (max-width: $sm)
			padding: 10px

	svg
			stroke-width: 3px !important
			top: 45% !important

.svg-circle-2
	p
		padding-left: var(--fix-2)
		@media screen and (max-width: $sm)
			padding-left: 0
	u
		padding: var(--fix-2)
	u
		svg
			top: 55% !important


.svg-circle, .svg-circle-2
	u
		display: inline-block
		position: relative
		text-decoration: none
		transition: color 0.6s ease
		svg
			position: absolute
			top: 50%
			left: 50%
			width: 100%
			height: 90%
			transform: translate(-50%, -50%)
			transform-origin: center center
			fill: none
			stroke-miterlimit: 10
			stroke: var(--color-blue)
			stroke-width: 3px
			pointer-events: none
			z-index: -1
			path
				stroke-dashoffset: 0
				stroke-dasharray: none

.svg-sun
	u
		display: inline-block
		position: relative
		text-decoration: none
		transition: color 0.6s ease
		svg
			position: absolute
			top: 70%
			left: 50%
			width: 100%
			height: 80%
			transform: translate(0%, -50%)
			transform-origin: center center
			stroke: var(--color-yellow)
			stroke-width: 4px
			stroke-miterlimit: 20
			pointer-events: none
			z-index: -1
			path
				stroke-dashoffset: 0
				stroke-dasharray: none

				


.svg-sun-2
	u
		display: inline-block
		position: relative
		text-decoration: none
		transition: color 0.6s ease
		svg
			position: absolute
			top: 38%
			left: 55%
			width: 100%
			height: 80%
			transform: translate(0%, -50%)
			transform-origin: center center
			stroke: var(--color-yellow)
			stroke-width: 3px
			pointer-events: none
			z-index: -1
			path
				stroke-dashoffset: 0
				stroke-dasharray: none				


.svg-line, .svg-line-2, .svg-line-3
	u
		display: inline-block
		position: relative
		text-decoration: none
		transition: color 0.6s ease
		svg
			position: absolute
			top: 50%
			left: 50%
			width: 100%
			height: 80%
			transform: translate(-50%, 0%)
			stroke: var(--color-yellow)
			stroke-width: 3px
			pointer-events: none
			transform-origin: center center
			z-index: -1
			fill: none
			path
				stroke-dashoffset: 0
				stroke-dasharray: none


.svg-line, .svg-line-2
	u
		svg
			stroke-width: 3px !important		
			stroke-miterlimit: 10
			stroke: var(--color-yellow)

.svg-line-3		
	u
		svg
			stroke-width:10px !important		
			stroke-miterlimit: 10
			stroke: var(--color-blue) !important




.svg-sun-3
	u
		display: inline-block
		position: relative
		text-decoration: none
		transition: color 0.6s ease
		svg
			position: absolute
			top: 50%
			left: 25%
			width: 30%
			height: 90%
			transform: translate(-50%, -50%)
			transform-origin: center center
			stroke: var(--color-blue)
			stroke-width: 4px
			pointer-events: none
			z-index: -1
			fill: none
			path
				stroke-dashoffset: 0
				stroke-dasharray: none	


.svg-sun-3-fix-1
	u
		//padding: var(--fix-1)
		padding-top: 70px !important
		padding-left: 60px !important	


.svg-sun-3-fix-2
	&.svg-circle-2
		u
			position: relative
			left: 15px
			svg
				width: 96% !important
				top: 68% !important
				left: 42% !important
	u
		//padding: var(--fix-1)
		padding-top: 70px !important
		padding-left: 0px !important	
		padding-right: 5px	

.content.padding-1
	h1
		.js-arts-split-text__wrapper-line
			padding: 50px 0px 20px 0px


.content.padding-2
	h1
		.js-arts-split-text__wrapper-line
			padding: 50px 0px 20px 0px	



.svg-both
	u
		display: inline-block
		position: relative
		text-decoration: none
		transition: color 0.6s ease
		.svg-1
			position: absolute
			top: 40%
			right: 0%
			width: 100%
			height: 140%
			transform: translate(55%, -50%)
			transform-origin: center center
			stroke: var(--color-yellow)
			stroke-width: 4px
			pointer-events: none
			z-index: -1
		.svg-2
			position: absolute
			top: 80%
			left: 50%
			width: 100%
			height: 80%
			transform: translate(-50%, 0%)
			stroke: var(--color-yellow)
			stroke-width: 3px
			pointer-events: none
			transform-origin: center center
			z-index: -1
		path
			stroke-dashoffset: 0
			stroke-dasharray: none				




.svg-sun-3-fix-2
	u svg
		top: calc( 50% + 5px  )