[data-arts-os-animation="true"] > *:not(.js-ajax-transition-visible-element)
	opacity: 0
	// visibility: hidden
	pointer-events: none !important
	*
		pointer-events: none !important
[data-arts-os-animation="true"]
	.js-ajax-transition-hidden-element
		opacity: 0
		visibility: hidden
		pointer-events: none !important
		*
			pointer-events: none !important
