.w-30
	width: 30% !important

.w-33
	width: 33% !important

.w-40
	width: 40% !important

+min-screen-md
	.w-lg-25
		width: 25% !important

	.w-lg-30
		width: 30% !important

	.w-lg-33
		width: 33% !important

	.w-lg-40
		width: 40% !important

	.w-lg-50
		width: 50% !important

	.w-lg-66
		width: 66% !important
