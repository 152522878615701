.b-opacity
	border: 1px solid var(--color-border-opacity)

.bt-opacity
	border-top: 1px solid var(--color-border-opacity)

.br-opacity
	border-right: 1px solid var(--color-border-opacity)

.bb-opacity
	border-bottom: 1px solid var(--color-border-opacity)

.bl-opacity
	border-left: 1px solid var(--color-border-opacity)
