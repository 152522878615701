.b-auto
	border: 1px solid var(--color-borders)

.bt-auto
	border-top: 1px solid rgba(#fff , 0.5)

.br-auto
	border-right: 1px solid var(--color-borders)

.bb-auto
	border-bottom: 1px solid var(--color-borders)

.bl-auto
	border-left: 1px solid var(--color-borders)
