.header_classic-menu-right
	.menu-classic
		.sub-menu
			left: auto
			right: 1em
			ul
				left: auto
				right: calc(100% + 1px)

.header_classic-menu-split-center
	.header__col_classic-right
		.menu-classic
			.sub-menu
				left: auto
				right: 1em
				ul
					left: auto
					right: calc(100% + 1px)

.header_overlay-logo-center-burger-left
	.header__overlay-menu-back
		top: var(--header-height)
	+max-screen-md
		.sub-menu-holder
			top: var(--header-height)
