.parallax
	display: flex
	flex-direction: column
	position: relative
.parallax__link
	display: inline-block
	max-width: 100%
.parallax__outer
	//
.parallax__caption
	margin-top: 1em

+max-screen-md
	.parallax__caption
		margin-bottom: 0.5em
