.properties__list
	+reset-list
	li
		display: block
		&:not(:last-child)
			margin-bottom: 1.0rem
		&:not(:first-child)
			margin-top: 1.0rem
.properties__name
	margin-bottom: 1.0rem
