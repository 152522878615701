@for $i from 2 through 10
	.h-#{$i * 100}
		max-height: 100vh
		height: $i * 100px

+min-screen-md
	.h-lg-100
		height: 100% !important
	.h-lg-75
		height: 75% !important
	.h-lg-50
		height: 50% !important
