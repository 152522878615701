.section
	position: relative
.section__content, .section__header
	position: relative
	z-index: 50
.section__background
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	width: 100%
	height: 100%
	max-width: 100%
	max-height: 100%
	z-index: 0
.section__overlay
	z-index: 10
	+trans1(opacity)
	will-change: opacity
.section_hovered
	.section__overlay
		opacity: 0.7

+min-screen-md
	.section__lg-background
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		width: 100%
		height: 100%
		max-width: 100%
		max-height: 100%
		z-index: 0
