.aspect-landscape
	aspect-ratio: 1.5 / 1 !important
.aspect-portrait
	--media-width: 1280
	--media-height: 1920
.aspect-portrait-2
	aspect-ratio: 1 / 1.33 !important
.aspect-square
	aspect-ratio: 1 / 1 !important
.aspect-unset
	aspect-ratio: unset !important
.has-aspect-ratio
	position: relative
	width: 100%
	max-width: 100%
	overflow: hidden
	&::before
		content: ''
		display: block
		padding-bottom: calc((var(--media-height) / var(--media-width)) * 100%)
	&::after
		content: ''
		display: table
		clear: both
	> img, > video
		position: absolute !important
		top: 0 !important
		left: 0 !important
		bottom: 0 !important
		right: 0 !important
