.post
	position: relative
	z-index: 50
.post__wrapper-date
	position: absolute
	top: 20px
	left: 20px
	z-index: 50
.post__media, .post__link
	position: relative
