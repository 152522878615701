.fw-200, .fw-200 p
	font-weight: 200 !important
.fw-300, .fw-300 p
	font-weight: 300 !important
.fw-400, .fw-400 p
	font-weight: 400 !important
.fw-500, .fw-500 p
	font-weight: 500 !important
.fw-600, .fw-600 p
	font-weight: 600 !important
.fw-700, .fw-700 p
	font-weight: 700 !important
.fw-800, .fw-800 p 
	font-weight: 800 !important


