.row:not([class*=g-])
	--bs-gutter-x: 40px
	--bs-gutter-y: 0
.g-default
	@include fluid('--bs-gutter-x', 20, 40)
	@include fluid('--bs-gutter-y', 30, 40)
.g-gutters
	--bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal))
	--bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical))
.g-gutters-horizontal
	--bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal))
	--bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal))
.g-gutters-vertical
	--bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical))
	--bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical))
.container-fluid-gutters
	width: 100%
	padding-left: var(--gutter-horizontal)
	padding-right: var(--gutter-horizontal)
	margin-left: auto
	margin-right: auto

+max-screen-lg
	.max-lg-pt-0
		padding-top: 0 !important
	.max-lg-px-0
		padding-left: 0 !important
		padding-right: 0 !important
	.max-lg-py-0
		padding-top: 0 !important
		padding-bottom: 0 !important
	.max-lg-pb-0
		padding-bottom: 0 !important
	.max-lg-p-0
		padding: 0 !important

	.max-lg-mt-0
		margin-top: 0 !important
	.max-lg-mx-0
		margin-left: 0 !important
		margin-right: 0 !important
	.max-lg-my-0
		margin-top: 0 !important
		margin-bottom: 0 !important
	.max-lg-mb-0
		margin-bottom: 0 !important
	.max-lg-m-0
		margin: 0 !important

+max-screen-md
	.max-md-pt-0
		padding-top: 0 !important
	.max-md-px-0
		padding-left: 0 !important
		padding-right: 0 !important
	.max-md-py-0
		padding-top: 0 !important
		padding-bottom: 0 !important
	.max-md-pb-0
		padding-bottom: 0 !important
	.max-md-p-0
		padding: 0 !important

	.max-md-mt-0
		margin-top: 0 !important
	.max-md-mx-0
		margin-left: 0 !important
		margin-right: 0 !important
	.max-md-my-0
		margin-top: 0 !important
		margin-bottom: 0 !important
	.max-md-mb-0
		margin-bottom: 0 !important
	.max-md-m-0
		margin: 0 !important

+max-screen-sm
	.max-sm-pt-0
		padding-top: 0 !important
	.max-sm-pb-0
		padding-bottom: 0 !important
	.max-sm-px-0
		padding-left: 0 !important
		padding-right: 0 !important
	.max-sm-py-0
		padding-top: 0 !important
		padding-bottom: 0 !important

	.max-sm-mt-0
		margin-top: 0 !important
	.max-sm-mx-0
		margin-left: 0 !important
		margin-right: 0 !important
	.max-sm-my-0
		margin-top: 0 !important
		margin-bottom: 0 !important
	.max-sm-mb-0
		margin-bottom: 0 !important
	.max-sm-m-0
		margin: 0 !important

// Bootstrap row gutters
.p-bs-gutters
	padding: var(--bs-gutter-x) var(--bs-gutter-y)

// Bootstrap row gutters [horizontal]
.px-bs-gutters
	padding-left: var(--bs-gutter-x)
	padding-right: var(--bs-gutter-x)
.ps-bs-gutters
	padding-left: var(--bs-gutter-x)
.pe-bs-gutters
	padding-right: var(--bs-gutter-x)

// Bootstrap row gutters [vertical]
.py-bs-gutters
	padding-top: var(--bs-gutter-y)
	padding-bottom: var(--bs-gutter-y)
.pt-bs-gutters
	padding-top: var(--bs-gutter-y)
.pb-bs-gutters
	padding-bottom: var(--bs-gutter-y)

+min-screen-md
	.px-lg-min-gutters
		padding-left: calc(var(--gutter-horizontal-min) * 1px)
		padding-right: calc(var(--gutter-horizontal-min) * 1px)
