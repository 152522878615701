@keyframes rotate
	100%
		transform: rotate(360deg)

@keyframes dash
	0%
		stroke-dasharray: 1, 200
		stroke-dashoffset: -1

	50%
		stroke-dasharray: 75, 200
		stroke-dashoffset: -75

	100%
		stroke-dasharray: 150, 200
		stroke-dashoffset: -150
