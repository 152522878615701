html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  -webkit-text-rendering: optimizeSpeed;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: none;
  scroll-behavior: initial !important;
  width: 100%;
  min-height: 100%;
  scrollbar-gutter: stable;
}

body {
  width: 100%;
  min-height: 100%;
  min-width: 320px;
  font-family: var(--paragraph-font-family);
  line-height: calc(1 * var(--paragraph-min-line-height) * 1px);
  color: var(--paragraph-color);
  font-size: 100%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  overflow-x: hidden;
}
@media screen and (min-width: 360px) {
  body {
    line-height: calc(1 * (var(--paragraph-min-line-height) * 1px + (var(--paragraph-max-line-height) - var(--paragraph-min-line-height)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  body {
    line-height: calc(1 * var(--paragraph-max-line-height) * 1px);
  }
}

.lock-scroll body {
  overflow: hidden !important;
}

img, iframe {
  max-width: 100%;
}

img {
  display: block;
  height: auto;
}

.page-wrapper {
  position: relative;
  z-index: 100;
  overflow: hidden;
}

.page-wrapper__content {
  position: relative;
  z-index: 100;
  overflow: hidden;
}

[tabindex] {
  outline: none;
}

.d-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-inline-flex-centered {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Typography */
:root {
  --font-primary: "Helvetica Neue", sans-serif;
  --font-secondary: "Calluna", serif;
  --font-tertiary: "Calluna Sans", sans-serif;
  --color-yellow: #FAE64D;
  --color-blue: #2142FF;
  /* Paragraph */
  --paragraph-font-family: var(--font-primary);
  --paragraph-min-font-size: 18;
  --paragraph-max-font-size: 20;
  --paragraph-min-line-height: 24;
  --paragraph-max-line-height: 32;
  --paragraph-letter-spacing: 0;
  --paragraph-color-light-theme: #000000;
  --paragraph-color-dark-theme: #ffffff;
  --paragraph-color: var(--paragraph-color-light-theme);
  /* Buttons */
  --ui-element-font-family: var(--font-primary);
  --ui-element-min-font-size: 14;
  --ui-element-max-font-size: 16;
  --ui-element-font-weight: 600;
  --ui-element-line-height: 1;
  --ui-element-letter-spacing: 0;
  --ui-element-color-light-theme: #000000;
  --ui-element-color-dark-theme: #fff;
  --ui-element-color: var(--ui-element-color-light-theme);
  /* Display XXL * */
  --display-xxl-font-family: var(--font-secondary);
  --display-xxl-max-font-size: 160;
  --display-xxl-min-font-size: 56;
  --display-xxl-font-weight: 200;
  --display-xxl-line-height: 1.3;
  --display-xxl-letter-spacing: 2px;
  --display-xxl-color-light-theme: #000000;
  --display-xxl-color-dark-theme: #ffffff;
  --display-xxl-color: var(--display-xxl-color-light-theme);
  /* Display XL * */
  --display-xl-font-family: var(--font-secondary);
  --display-xl-max-font-size: 160;
  --display-xl-min-font-size: 56;
  --display-xl-font-weight: 200;
  --display-xl-line-height: 1.2;
  --display-xl-letter-spacing: -0.02em;
  --display-xl-color-light-theme: #000000;
  --display-xl-color-dark-theme: #ffffff;
  --display-xl-color: var(--display-xl-color-light-theme);
  /* Display caps */
  --display-caps-font-family: var(--font-secondary);
  --display-caps-max-font-size: 144;
  --display-caps-min-font-size: 30;
  --display-caps-font-weight: 200;
  --display-caps-line-height: 1.2;
  --display-caps-letter-spacing: 0.06em;
  --display-caps-color-light-theme: #000000;
  --display-caps-color-dark-theme: #ffffff;
  --display-caps-color: var(--display-caps-color-light-theme);
  /* Heading 1 */
  --h1-font-family: var(--font-secondary);
  --h1-max-font-size: 52;
  --h1-min-font-size: 40;
  --h1-font-weight: 200;
  --h1-line-height: 1.1;
  --h1-letter-spacing: 0;
  --h1-color-light-theme: #000000;
  --h1-color-dark-theme: #ffffff;
  --h1-color: var(--h1-color-light-theme);
  /* Heading 2 */
  --h2-font-family: var(--font-secondary);
  --h2-max-font-size: 42;
  --h2-min-font-size: 28;
  --h2-font-weight: 300;
  --h2-line-height: 1.2;
  --h2-letter-spacing: 0px;
  --h2-color-light-theme: #000000;
  --h2-color-dark-theme: #ffffff;
  --h2-color: var(--h2-color-light-theme);
  /* Heading 3 */
  --h3-font-family: var(--font-sprimary);
  --h3-max-font-size: 24;
  --h3-min-font-size: 17;
  --h3-font-weight: 400;
  --h3-line-height: 1.3;
  --h3-letter-spacing: 0px;
  --h3-color-light-theme: #000000;
  --h3-color-dark-theme: #ffffff;
  --h3-color: var(--h3-color-light-theme);
  /* Heading 4 */
  --h4-font-family: var(--font-secondary);
  --h4-max-font-size: 24;
  --h4-min-font-size: 20;
  --h4-font-weight: 400;
  --h4-line-height: 1.3;
  --h4-letter-spacing: 0;
  --h4-color-light-theme: #000000;
  --h4-color-dark-theme: #ffffff;
  --h4-color: var(--h4-color-light-theme);
  /* Heading 5 */
  --h5-font-family: var(--font-primary);
  --h5-max-font-size: 19;
  --h5-min-font-size: 17;
  --h5-font-weight: 700;
  --h5-line-height: 1.25;
  --h5-letter-spacing: 0px;
  --h5-color-light-theme: #000000;
  --h5-color-dark-theme: #ffffff;
  --h5-color: var(--h5-color-light-theme);
  /* Heading 6 */
  --h6-font-family: var(--font-primary);
  --h6-max-font-size: 19;
  --h6-min-font-size: 15;
  --h6-font-weight: 500;
  --h6-line-height: 1.6;
  --h6-letter-spacing: 2px;
  --h6-color-light-theme: #000000;
  --h6-color-dark-theme: #ffffff;
  --h6-color: var(--h6-color-light-theme);
  /* Blockquote */
  --blockquote-font-family: var(--font-primary);
  --blockquote-max-font-size: 24;
  --blockquote-min-font-size: 18;
  --blockquote-font-weight: 400;
  --blockquote-line-height: 1.33;
  --blockquote-letter-spacing: 0;
  --blockquote-color-light-theme: #000000;
  --blockquote-color-dark-theme: #ffffff;
  --blockquote-color: var(--blockquote-color-light-theme);
  /* Dropcap */
  --dropcap-font-family: var(--font-secondary);
  --dropcap-max-font-size: 112;
  --dropcap-min-font-size: 52;
  --dropcap-font-weight: 200;
  --dropcap-line-height: 0.7;
  --dropcap-letter-spacing: 0;
  --dropcap-color-light-theme: #000000;
  --dropcap-color-dark-theme: #ffffff;
  --dropcap-color: var(--dropcap-color-light-theme);
  /* Subheading */
  --subheading-font-family: var(--font-primary);
  --subheading-max-font-size: 13;
  --subheading-min-font-size: 11;
  --subheading-font-weight: 700;
  --subheading-line-height: 1.3;
  --subheading-letter-spacing: 0.15em;
  --subheading-color-light-theme: #000000;
  --subheading-color-dark-theme: #ffffff;
  --subheading-color: var(--subheading-color-light-theme);
  /* Overlay menu item */
  --overlay-menu-item-font-family: var(--font-primary);
  --overlay-menu-item-max-font-size: 42;
  --overlay-menu-item-min-font-size: 34;
  --overlay-menu-item-font-weight: 400;
  --overlay-menu-item-line-height: 1.2;
  --overlay-menu-item-letter-spacing: 0em;
  --overlay-menu-item-color-light-theme: #000000;
  --overlay-menu-item-color-dark-theme: #ffffff;
  --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
  /* Overlay submenu item */
  --overlay-submenu-item-font-family: var(--font-secondary);
  --overlay-submenu-item-max-font-size: 54;
  --overlay-submenu-item-min-font-size: 32;
  --overlay-submenu-item-font-weight: 300;
  --overlay-submenu-item-line-height: 1.3;
  --overlay-submenu-item-letter-spacing: 0;
  --overlay-submenu-item-color-light-theme: #000000;
  --overlay-submenu-item-color-dark-theme: #ffffff;
  --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
  /* Super label */
  --super-label-font-family: var(--font-primary);
  --super-label-max-font-size: 18;
  --super-label-min-font-size: 12;
  --super-label-font-weight: 700;
  --super-label-line-height: 1;
  --super-label-letter-spacing: 0;
  --super-label-color-light-theme: #000000;
  --super-label-color-dark-theme: #ffffff;
  --super-label-color: var(--super-label-color-light-theme);
}

/* Distances */
:root {
  /* Fluid paddings & margins (min values) */
  --distance-min-xxsmall: 20;
  --distance-min-xsmall: 30;
  --distance-min-small: 40;
  --distance-min-medium: 70;
  --distance-min-large: 50;
  --distance-min-xlarge: 150;
  /* Fluid paddings & margins (max values) */
  --distance-max-xxsmall: 30;
  --distance-max-xsmall: 40;
  --distance-max-small: 80;
  --distance-max-medium: 120;
  --distance-max-large: 180;
  --distance-max-xlarge: 200;
  /* Container & page gutters */
  --gutter-horizontal-min: 20;
  --gutter-horizontal-max: 120;
  --gutter-vertical-min: 20;
  --gutter-vertical-max: 80;
  --gutter-horizontal: calc(1 * var(--gutter-horizontal-min) * 1px);
  --gutter-vertical: calc(1 * var(--gutter-vertical-min) * 1px);
  /* Bootstrap */
  --bs-gutter-x: 20px;
  --bs-gutter-y: 20px;
}
@media screen and (min-width: 360px) {
  :root {
    --gutter-horizontal: calc(1 * (var(--gutter-horizontal-min) * 1px + (var(--gutter-horizontal-max) - var(--gutter-horizontal-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  :root {
    --gutter-horizontal: calc(1 * var(--gutter-horizontal-max) * 1px);
  }
}
@media screen and (min-width: 360px) {
  :root {
    --gutter-vertical: calc(1 * (var(--gutter-vertical-min) * 1px + (var(--gutter-vertical-max) - var(--gutter-vertical-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  :root {
    --gutter-vertical: calc(1 * var(--gutter-vertical-max) * 1px);
  }
}

/* Colors */
:root {
  /* Dark colors */
  --color-dark-1: #000000;
  --color-dark-1-rgb: 32, 33, 31;
  --color-dark-2: #1B1A18;
  --color-dark-2-rgb: 27, 26, 24;
  --color-dark-3: #313130;
  --color-dark-3-rgb: 49, 49, 48;
  --color-dark-4: #060707;
  --color-dark-4-rgb: 6, 7, 7;
  /* Gray colors */
  --color-gray-1: #6f6e6f;
  --color-gray-1-rgb: 147, 149, 151;
  --color-gray-2: #F5F5F5;
  --color-gray-2-rgb: 192, 192, 192;
  --color-gray-3: #777777;
  --color-gray-3-rgb: 119, 119, 119;
  --color-gray-4: #888888;
  --color-gray-4-rgb: 136, 136, 136;
  /* Light colors */
  --color-light-1: #fff;
  --color-light-1-rgb: 219, 218, 215;
  --color-light-2: #E8E6E0;
  --color-light-2-rgb: 232, 230, 224;
  --color-light-3: #F1F2ED;
  --color-light-3-rgb: 241, 242, 237;
  --color-light-4: #FFFFFF;
  --color-light-4-rgb: 255, 255, 255;
  /* Platinum colors */
  --color-platinum-1: #767B75;
  --color-platinum-1-rgb: 118, 123, 117;
  --color-platinum-2: #686D67;
  --color-platinum-2-rgb: 104, 109, 103;
  /* Accent colors */
  --color-accent-light-theme: #000;
  --color-accent-light-theme-rgb: 101, 100, 83;
  --color-accent-dark-theme: #FAE64D;
  --color-accent-dark-theme-rgb: 253, 249, 207;
  --color-accent: var(--color-accent-light-theme);
  --color-accent-rgb: 101, 100, 83;
  /* Border colors */
  --color-border-opacity-light-theme: rgba(32, 33, 32, .2);
  --color-border-opacity-dark-theme: rgba(255, 255, 255, .2);
  --color-border-opacity: var(--color-border-opacity-light-theme);
  --color-border-solid-light-theme: #000000;
  --color-border-solid-dark-theme: #fff;
  --color-border-solid: var(--color-border-solid-light-theme);
  /* Links colors */
  --color-links-light-theme: #000;
  --color-links-dark-theme: #fff;
  --color-links: var(--color-links-light-theme);
}

/* Header */
:root {
  /* Logo */
  --logo-max-width: 140;
  --logo-min-width: 110;
  /* Menu  */
  --menu-font-weight: 400;
  --menu-letter-spacing: 1px;
  --menu-font-family: var(--font-primary);
  --menu-max-font-size: 17;
  --menu-min-font-size: 14;
  --menu-max-margin-right: 80;
  --menu-min-margin-right: 60;
  --menu-font-text-transform: none;
  --color-menu-links-light-theme: #0E1E2E;
  --color-menu-links-dark-theme: #fff;
  --color-menu-links: var(--color-links-light-theme);
  /* Submenu */
  --color-classic-submenu-links: #0E1E2E;
  --color-classic-submenu-links-hover: #656453;
  --color-classic-submenu-links-hover-background: #65645315;
}

[data-arts-os-animation=true] > *:not(.js-ajax-transition-visible-element) {
  opacity: 0;
  pointer-events: none !important;
}
[data-arts-os-animation=true] > *:not(.js-ajax-transition-visible-element) * {
  pointer-events: none !important;
}

[data-arts-os-animation=true] .js-ajax-transition-hidden-element {
  opacity: 0;
  visibility: hidden;
  pointer-events: none !important;
}
[data-arts-os-animation=true] .js-ajax-transition-hidden-element * {
  pointer-events: none !important;
}

.svg-arrow path {
  fill: currentColor;
}

@media screen and (min-width: 1025px) {
  #nosotros {
    margin-top: 0;
  }
}
#nosotros .col-top {
  position: relative;
  top: calc(1 * -40 * 1px);
  margin-bottom: calc(1 * -40 * 1px);
}
@media screen and (min-width: 360px) {
  #nosotros .col-top {
    top: calc(1 * (-40 * 1px + (-80 - -40) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  #nosotros .col-top {
    top: calc(1 * -80 * 1px);
  }
}
@media screen and (min-width: 360px) {
  #nosotros .col-top {
    margin-bottom: calc(1 * (-40 * 1px + (-80 - -40) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  #nosotros .col-top {
    margin-bottom: calc(1 * -80 * 1px);
  }
}
@media screen and (max-width: 1024px) {
  #nosotros .col-top {
    top: 0;
    margin-bottom: 0;
  }
}

/* CIRCLES */ 
.circle-arrow {
  background: var(--color-yellow);
  width: calc(1 * 80 * 1px);
  height: calc(1 * 80 * 1px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 360px) {
  .circle-arrow {
    width: calc(1 * (80 * 1px + (130 - 80) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .circle-arrow {
    width: calc(1 * 130 * 1px);
  }
}
@media screen and (min-width: 360px) {
  .circle-arrow {
    height: calc(1 * (80 * 1px + (130 - 80) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .circle-arrow {
    height: calc(1 * 130 * 1px);
  }
}
.circle-arrow svg {
  animation: bounce 3s infinite;
  width: calc(1 * 15 * 1px);
}
@media screen and (min-width: 360px) {
  .circle-arrow svg {
    width: calc(1 * (15 * 1px + (20 - 15) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .circle-arrow svg {
    width: calc(1 * 20 * 1px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(15px);
  }
  60% {
    transform: translateY(10px);
  }
}
.circles-wrapper .circle {
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: calc(1 * 10 * 1px);
  transition: all 0.3s ease;
}
@media screen and (min-width: 360px) {
  .circles-wrapper .circle {
    padding: calc(1 * (10 * 1px + (40 - 10) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .circles-wrapper .circle {
    padding: calc(1 * 40 * 1px);
  }
}
.circles-wrapper .hovered .circle {
  background: var(--color-yellow);
  border-color: var(--color-yellow);
  transform: scale(1.2);
  transition: all 0.3s ease;
}
.circles-wrapper .circle {
  margin-bottom: 1.5vw;
}

@media screen and (min-width: 992px) {
  .item-1 {
    margin-top: 10vw;
  }
  .item-5 {
    margin-top: 10vw;
  }
  .item-3 {
    margin-top: 20vw;
  }
}
@media screen and (max-width: 991px) {
  .circles-wrapper {
    max-width: 80vw;
    margin: 0 auto;
  }
  .circles-wrapper .item-3 {
    margin-top: 100px;
  }
  .circles-wrapper .circle {
    margin-bottom: 20px;
  }
  .circles-wrapper .col-6:nth-child(3) {
    position: relative;
    top: -100px;
  }
}
@media screen and (max-width: 767px) {
  .circles-wrapper {
    max-width: 100%;
  }
}
/* ARROW */ 
.arrow-svg {
  width: calc(1 * 60 * 1px);
  margin-right: calc(1 * 20 * 1px);
}
@media screen and (min-width: 360px) {
  .arrow-svg {
    width: calc(1 * (60 * 1px + (300 - 60) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .arrow-svg {
    width: calc(1 * 300 * 1px);
  }
}
@media screen and (min-width: 360px) {
  .arrow-svg {
    margin-right: calc(1 * (20 * 1px + (80 - 20) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .arrow-svg {
    margin-right: calc(1 * 80 * 1px);
  }
}

/* EQUIPO */ 
.equipo {
  cursor: pointer;
  text-align: center;
  transition: transform 0.4s ease;
}
.equipo.no-link {
  pointer-events: none;
}
.equipo:hover {
  transition: transform 0.4s ease;
  transform: scale(0.95);
}

@media (min-width: 992px) and (max-height: 880px) {
  .intro {
    padding-top: 8vh !important;
  }
  .intro h2 {
    margin-bottom: 10px !important;
    font-size: 18px;
  }
  .intro .xxl {
    font-size: 17vh;
    line-height: 1.1;
  }
  #herramientas .xl {
    font-size: 16vh;
    line-height: 1.15 !important;
  }
  #ayudamos {
    padding-top: 17vh !important;
    padding-bottom: 18vh !important;
  }
  #ayudamos .xl-fix {
    font-size: 16vh;
  }
  #contacto h1 {
    font-size: 17vh;
  }
}
.scroll {
  position: absolute;
  top: -110px;
}

.aspect-landscape {
  aspect-ratio: 1.5/1 !important;
}

.aspect-portrait {
  --media-width: 1280;
  --media-height: 1920;
}

.aspect-portrait-2 {
  aspect-ratio: 1/1.33 !important;
}

.aspect-square {
  aspect-ratio: 1/1 !important;
}

.aspect-unset {
  aspect-ratio: unset !important;
}

.has-aspect-ratio {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.has-aspect-ratio::before {
  content: "";
  display: block;
  padding-bottom: calc(var(--media-height) / var(--media-width) * 100%);
}
.has-aspect-ratio::after {
  content: "";
  display: table;
  clear: both;
}
.has-aspect-ratio > img, .has-aspect-ratio > video {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.row:not([class*=g-]) {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
}

.g-default {
  --bs-gutter-x: calc(1 * 20 * 1px);
  --bs-gutter-y: calc(1 * 30 * 1px);
}
@media screen and (min-width: 360px) {
  .g-default {
    --bs-gutter-x: calc(1 * (20 * 1px + (40 - 20) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .g-default {
    --bs-gutter-x: calc(1 * 40 * 1px);
  }
}
@media screen and (min-width: 360px) {
  .g-default {
    --bs-gutter-y: calc(1 * (30 * 1px + (40 - 30) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .g-default {
    --bs-gutter-y: calc(1 * 40 * 1px);
  }
}

.g-gutters {
  --bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal));
  --bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical));
}

.g-gutters-horizontal {
  --bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal));
  --bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-horizontal));
}

.g-gutters-vertical {
  --bs-gutter-x: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical));
  --bs-gutter-y: max(calc(var(--distance-min-xsmall) * 1px), var(--gutter-vertical));
}

.container-fluid-gutters {
  width: 100%;
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .max-lg-pt-0 {
    padding-top: 0 !important;
  }
  .max-lg-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .max-lg-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .max-lg-pb-0 {
    padding-bottom: 0 !important;
  }
  .max-lg-p-0 {
    padding: 0 !important;
  }
  .max-lg-mt-0 {
    margin-top: 0 !important;
  }
  .max-lg-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .max-lg-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .max-lg-mb-0 {
    margin-bottom: 0 !important;
  }
  .max-lg-m-0 {
    margin: 0 !important;
  }
}
@media screen and (max-width: 991px) {
  .max-md-pt-0 {
    padding-top: 0 !important;
  }
  .max-md-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .max-md-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .max-md-pb-0 {
    padding-bottom: 0 !important;
  }
  .max-md-p-0 {
    padding: 0 !important;
  }
  .max-md-mt-0 {
    margin-top: 0 !important;
  }
  .max-md-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .max-md-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .max-md-mb-0 {
    margin-bottom: 0 !important;
  }
  .max-md-m-0 {
    margin: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .max-sm-pt-0 {
    padding-top: 0 !important;
  }
  .max-sm-pb-0 {
    padding-bottom: 0 !important;
  }
  .max-sm-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .max-sm-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .max-sm-mt-0 {
    margin-top: 0 !important;
  }
  .max-sm-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .max-sm-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .max-sm-mb-0 {
    margin-bottom: 0 !important;
  }
  .max-sm-m-0 {
    margin: 0 !important;
  }
}
.p-bs-gutters {
  padding: var(--bs-gutter-x) var(--bs-gutter-y);
}

.px-bs-gutters {
  padding-left: var(--bs-gutter-x);
  padding-right: var(--bs-gutter-x);
}

.ps-bs-gutters {
  padding-left: var(--bs-gutter-x);
}

.pe-bs-gutters {
  padding-right: var(--bs-gutter-x);
}

.py-bs-gutters {
  padding-top: var(--bs-gutter-y);
  padding-bottom: var(--bs-gutter-y);
}

.pt-bs-gutters {
  padding-top: var(--bs-gutter-y);
}

.pb-bs-gutters {
  padding-bottom: var(--bs-gutter-y);
}

@media screen and (min-width: 992px) {
  .px-lg-min-gutters {
    padding-left: calc(var(--gutter-horizontal-min) * 1px);
    padding-right: calc(var(--gutter-horizontal-min) * 1px);
  }
}
.w-container-edge-end {
  max-width: 100%;
  width: calc(100% - (100vw - 1320px) / 2 - var(--bs-gutter-x));
  margin-left: auto;
  text-align: left;
}

.w-container-edge-start {
  max-width: 100%;
  width: calc(100% - (100vw - 1320px) / 2 - var(--bs-gutter-x));
  margin-right: auto;
  text-align: right;
}

@media screen and (max-width: 1400px) {
  .w-container-edge-end, .w-container-edge-start {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .w-container-edge-end {
    width: calc(100% - (100vw - 1140px) / 2 - var(--bs-gutter-x));
  }
  .w-container-edge-start {
    width: calc(100% - (100vw - 1140px) / 2 - var(--bs-gutter-x));
  }
}
.b-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border: 1px solid var(--color-borders);
}

.bt-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border-top: 1px solid var(--color-borders);
}

.br-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border-right: 1px solid var(--color-borders);
}

.bb-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border-bottom: 1px solid var(--color-borders);
}

.bl-auto-opacity-solid {
  --color-borders: var(--color-border-solid);
  border-left: 1px solid var(--color-borders);
}

.borders-solid {
  --color-borders: var(--color-border-solid) !important;
}

.borders-opacity {
  --color-borders: var(--color-border-opacity) !important;
}

.borders-auto-opacity-solid {
  --color-borders: var(--color-border-solid) !important;
}

[data-arts-color-theme=dark] .borders-auto-opacity-solid, [data-arts-color-theme=dark] .b-auto-opacity-solid .bt-auto-opacity-solid, [data-arts-color-theme=dark] .br-auto-opacity-solid, [data-arts-color-theme=dark] .bb-auto-opacity-solid, [data-arts-color-theme=dark] .bl-auto-opacity-solid {
  --color-borders: var(--color-border-opacity) !important;
}

.borders-auto-opacity-solid[data-arts-color-theme=dark], .b-auto-opacity-solid .bt-auto-opacity-solid[data-arts-color-theme=dark], .br-auto-opacity-solid[data-arts-color-theme=dark], .bb-auto-opacity-solid[data-arts-color-theme=dark], .bl-auto-opacity-solid[data-arts-color-theme=dark] {
  --color-borders: var(--color-border-opacity) !important;
}

.b-auto {
  border: 1px solid var(--color-borders);
}

.bt-auto {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.br-auto {
  border-right: 1px solid var(--color-borders);
}

.bb-auto {
  border-bottom: 1px solid var(--color-borders);
}

.bl-auto {
  border-left: 1px solid var(--color-borders);
}

.bg-bt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1px;
  background-color: var(--color-borders);
}

.bg-br {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: var(--color-borders);
}

.bg-bb {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: var(--color-borders);
}

.bg-bl {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: var(--color-borders);
}

.b-opacity {
  border: 1px solid var(--color-border-opacity);
}

.bt-opacity {
  border-top: 1px solid var(--color-border-opacity);
}

.br-opacity {
  border-right: 1px solid var(--color-border-opacity);
}

.bb-opacity {
  border-bottom: 1px solid var(--color-border-opacity);
}

.bl-opacity {
  border-left: 1px solid var(--color-border-opacity);
}

.b-solid {
  border: 1px solid var(--color-blue);
}
@media screen and (max-width: 991px) {
  .b-solid {
    border: 1px solid var(--color-blue);
  }
}

.bt-solid {
  border-top: 1px solid var(--color-border-solid);
}

.br-solid {
  border-right: 1px solid var(--color-border-solid);
}

.bb-solid {
  border-bottom: 1px solid var(--color-yellow);
}

.bl-solid {
  border-left: 1px solid var(--color-border-solid);
}

.categories {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.categories li {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
}
.categories li:not(:last-child):after {
  content: "/";
  display: inline-block;
  margin-left: 0.6em;
  margin-right: 0.6em;
}

.cursor-none {
  cursor: none !important;
}
.cursor-none * {
  cursor: none !important;
}

.cursor-progress {
  cursor: progress !important;
}
.cursor-progress * {
  cursor: progress !important;
}

.pointer-events-none {
  pointer-events: none !important;
}
.pointer-events-none * {
  pointer-events: none !important;
}

.pointer-events-inner-none * {
  pointer-events: none !important;
}

.pointer-events-links-none a {
  pointer-events: !important;
}

a.pointer-events-links-none {
  pointer-events: !important;
}

.cursor-pointer {
  cursor: pointer;
}

.arts-cursor {
  --cursor-color: var(--color-accent-light-theme);
  --cursor-color-dark-theme: var(--color-accent-dark-theme);
  --cursor-border-width: 1px;
  --cursor-background-color: transparent;
  --cursor-background-color-dark-theme: transparent;
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(0);
  user-select: none;
  pointer-events: none;
  z-index: 100001;
  color: var(--cursor-color, transparent);
}
.arts-cursor * {
  user-select: none;
  pointer-events: none;
}

.has-cursor-follower .arts-cursor {
  transform: translate3d(calc(var(--translateX, 0) - 50%), calc(var(--translateY, 0) - 50%), 0) rotate3d(0, 0, 1, var(--rotate, 0)) scale3d(var(--scaleX, 0), var(--scaleY, 0), 1);
}
.has-cursor-follower .arts-cursor__follower {
  transform: scale(calc(var(--scale, 1) * var(--scale-pressed, 1)));
}
.has-cursor-follower .arts-cursor__wrapper {
  transform: rotate3d(0, 0, 1, var(--rotate, 0));
}

.arts-cursor__follower {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 var(--cursor-border-width, 1px) currentColor;
  background-color: var(--color-yellow, transparent);
}

.arts-cursor__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.arts-cursor__arrow {
  position: absolute;
  margin: auto;
  font-size: 24px !important;
  width: 24px;
  height: 24px;
  opacity: 0;
  visibility: hidden;
}

.arts-cursor__arrow_left {
  top: 0;
  bottom: 0;
  left: 0;
}

.arts-cursor__arrow_right {
  top: 0;
  bottom: 0;
  right: 0;
}

.arts-cursor__arrow_up {
  top: 0;
  left: 0;
  right: 0;
}

.arts-cursor__arrow_down {
  bottom: 0;
  left: 0;
  right: 0;
}

.arts-cursor__wrapper-label, .arts-cursor__wrapper-icon, .arts-cursor__wrapper-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.arts-cursor__label {
  display: block;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
}

.arts-cursor__icon {
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  font-size: 28px !important;
  width: 28px;
  height: 28px;
  vertical-align: middle;
}
.arts-cursor__icon:before {
  margin: 0;
  vertical-align: top;
}

.arts-cursor-none {
  cursor: none !important;
}
.arts-cursor-none * {
  cursor: none !important;
}

.arts-cursor-progress {
  cursor: progress !important;
}
.arts-cursor-progress * {
  cursor: progress !important;
}

.arts-cursor__label {
  font-size: 14px;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0;
}

.arts-is-dragging {
  cursor: none !important;
}
.arts-is-dragging * {
  cursor: none !important;
}
.arts-is-dragging .arts-is-dragging__blocker {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

.arts-is-dragging__blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.2s;
}

.arts-cursor__wrapper-loading {
  transform: translate(-50%, -50%) scale(0);
  width: 100%;
  height: 100%;
}
.arts-cursor__wrapper-loading svg {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: auto;
}
.arts-cursor__wrapper-loading svg circle {
  stroke: currentColor;
  stroke-width: 2px;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -150;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

.arts-cursor[data-arts-color-theme=dark], #page-wrapper[data-arts-color-theme=dark] + .arts-cursor {
  --color-accent: var(--color-accent-dark-theme);
  --cursor-color: var(--cursor-color-dark-theme);
  --cursor-background-color: var(--cursor-background-color-dark-theme);
}

.bg-dark-1 {
  background-color: var(--color-dark-1) !important;
  --overlay-rgb: var(--color-dark-1-rgb) !important;
}

.bg-dark-2 {
  background-color: var(--color-dark-2) !important;
  --overlay-rgb: var(--color-dark-2-rgb) !important;
}

.bg-dark-3 {
  background-color: var(--color-dark-3) !important;
  --overlay-rgb: var(--color-dark-3-rgb) !important;
}

.bg-dark-4 {
  background-color: var(--color-dark-4) !important;
  --overlay-rgb: var(--color-dark-4-rgb) !important;
}

.bg-gray-1 {
  background-color: var(--color-gray-1) !important;
  --overlay-rgb: var(--color-gray-1-rgb) !important;
}

.bg-gray-2 {
  background-color: var(--color-gray-2) !important;
  --overlay-rgb: var(--color-gray-2-rgb) !important;
}

.bg-gray-3 {
  background-color: var(--color-gray-3) !important;
  --overlay-rgb: var(--color-gray-3-rgb) !important;
}

.bg-gray-4 {
  background-color: var(--color-gray-4) !important;
  --overlay-rgb: var(--color-gray-4-rgb) !important;
}

.bg-light-1 {
  background-color: var(--color-light-1) !important;
  --overlay-rgb: var(--color-light-1-rgb) !important;
}

.bg-light-2 {
  background-color: var(--color-light-2) !important;
  --overlay-rgb: var(--color-light-2-rgb) !important;
}

.bg-light-3 {
  background-color: var(--color-light-3) !important;
  --overlay-rgb: var(--color-light-3-rgb) !important;
}

.bg-light-4 {
  background-color: var(--color-light-4) !important;
  --overlay-rgb: var(--color-light-4-rgb) !important;
}

.bg-platinum-1 {
  background-color: var(--color-platinum-1) !important;
  --overlay-rgb: var(--color-platinum-1-rgb) !important;
}

.bg-platinum-2 {
  background-color: var(--color-platinum-2) !important;
  --overlay-rgb: var(--color-platinum-2-rgb) !important;
}

.bg-accent {
  background-color: var(--color-accent) !important;
  --overlay-rgb: var(--color-accent-rgb) !important;
}

.bg-accent-dark-theme {
  background-color: var(--color-accent-dark-theme) !important;
  --overlay-rgb: var(--color-accent-dark-theme-rgb) !important;
}

.bg-accent-light-theme {
  background-color: var(--color-accent-light-theme) !important;
  --overlay-rgb: var(--color-accent-light-theme-rgb) !important;
}

.color-accent {
  color: var(--color-accent) !important;
}

.color-accent-dark-theme {
  color: var(--color-accent-dark-theme) !important;
}

.color-accent-light-theme {
  color: var(--color-accent-light-theme) !important;
}

.color-light-theme {
  color: var(--paragraph-color-light-theme) !important;
}

.color-dark-theme {
  color: var(--paragraph-color-dark-theme) !important;
}

.color-dark-1 {
  color: var(--color-dark-1) !important;
}

.color-dark-2 {
  color: var(--color-dark-2) !important;
}

.color-dark-3 {
  color: var(--color-dark-3) !important;
}

.color-dark-4 {
  color: var(--color-dark-4) !important;
}

.color-gray-1 {
  color: var(--color-gray-1) !important;
}

.color-gray-2 {
  color: var(--color-gray-2) !important;
}

.color-gray-3 {
  color: var(--color-gray-3) !important;
}

.color-gray-4 {
  color: var(--color-gray-4) !important;
}

.color-blue {
  color: var(--color-blue) !important;
}

.color-yellow {
  color: var(--color-yellow) !important;
}

:root {
  --fix-1: 20px;
  --fix-2: 25px;
}

.svg-circle .fix-svg-1 {
  padding-left: var(--fix-1);
}
@media screen and (max-width: 767px) {
  .svg-circle .fix-svg-1 {
    padding-left: 0;
  }
}
.svg-circle .fix-svg-2 {
  position: relative;
  left: var(--fix-1);
}
@media screen and (max-width: 767px) {
  .svg-circle .fix-svg-2 {
    left: auto !important;
  }
}
@media screen and (max-width: 767px) {
  .svg-circle .fix-svg-3 {
    left: calc(-1 * var(--fix-1) / 2);
  }
}
.svg-circle p {
  padding-left: var(--fix-1);
}
@media screen and (max-width: 767px) {
  .svg-circle p {
    padding-left: 0;
  }
}
.svg-circle u {
  padding: var(--fix-1);
}
@media screen and (max-width: 767px) {
  .svg-circle .fix-sm {
    padding: 10px;
  }
}
.svg-circle svg {
  stroke-width: 3px !important;
  top: 45% !important;
}

.svg-circle-2 p {
  padding-left: var(--fix-2);
}
@media screen and (max-width: 767px) {
  .svg-circle-2 p {
    padding-left: 0;
  }
}
.svg-circle-2 u {
  padding: var(--fix-2);
}
.svg-circle-2 u svg {
  top: 55% !important;
}

.svg-circle u, .svg-circle-2 u {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.6s ease;
}
.svg-circle u svg, .svg-circle-2 u svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 90%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  fill: none;
  stroke-miterlimit: 10;
  stroke: var(--color-blue);
  stroke-width: 3px;
  pointer-events: none;
  z-index: -1;
}
.svg-circle u svg path, .svg-circle-2 u svg path {
  stroke-dashoffset: 0;
  stroke-dasharray: none;
}

.svg-sun u {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.6s ease;
}
.svg-sun u svg {
  position: absolute;
  top: 70%;
  left: 50%;
  width: 100%;
  height: 80%;
  transform: translate(0%, -50%);
  transform-origin: center center;
  stroke: var(--color-yellow);
  stroke-width: 4px;
  stroke-miterlimit: 20;
  pointer-events: none;
  z-index: -1;
}
.svg-sun u svg path {
  stroke-dashoffset: 0;
  stroke-dasharray: none;
}

.svg-sun-2 u {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.6s ease;
}
.svg-sun-2 u svg {
  position: absolute;
  top: 38%;
  left: 55%;
  width: 100%;
  height: 80%;
  transform: translate(0%, -50%);
  transform-origin: center center;
  stroke: var(--color-yellow);
  stroke-width: 3px;
  pointer-events: none;
  z-index: -1;
}
.svg-sun-2 u svg path {
  stroke-dashoffset: 0;
  stroke-dasharray: none;
}

.svg-line u, .svg-line-2 u, .svg-line-3 u {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.6s ease;
}
.svg-line u svg, .svg-line-2 u svg, .svg-line-3 u svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 80%;
  transform: translate(-50%, 0%);
  stroke: var(--color-yellow);
  stroke-width: 3px;
  pointer-events: none;
  transform-origin: center center;
  z-index: -1;
  fill: none;
}
.svg-line u svg path, .svg-line-2 u svg path, .svg-line-3 u svg path {
  stroke-dashoffset: 0;
  stroke-dasharray: none;
}

.svg-line u svg, .svg-line-2 u svg {
  stroke-width: 3px !important;
  stroke-miterlimit: 10;
  stroke: var(--color-yellow);
}

.svg-line-3 u svg {
  stroke-width: 10px !important;
  stroke-miterlimit: 10;
  stroke: var(--color-blue) !important;
}

.svg-sun-3 u {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.6s ease;
}
.svg-sun-3 u svg {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 30%;
  height: 90%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  stroke: var(--color-blue);
  stroke-width: 4px;
  pointer-events: none;
  z-index: -1;
  fill: none;
}
.svg-sun-3 u svg path {
  stroke-dashoffset: 0;
  stroke-dasharray: none;
}

.svg-sun-3-fix-1 u {
  padding-top: 70px !important;
  padding-left: 60px !important;
}

.svg-sun-3-fix-2.svg-circle-2 u {
  position: relative;
  left: 15px;
}
.svg-sun-3-fix-2.svg-circle-2 u svg {
  width: 96% !important;
  top: 68% !important;
  left: 42% !important;
}
.svg-sun-3-fix-2 u {
  padding-top: 70px !important;
  padding-left: 0px !important;
  padding-right: 5px;
}

.content.padding-1 h1 .js-arts-split-text__wrapper-line {
  padding: 50px 0px 20px 0px;
}

.content.padding-2 h1 .js-arts-split-text__wrapper-line {
  padding: 50px 0px 20px 0px;
}

.svg-both u {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.6s ease;
}
.svg-both u .svg-1 {
  position: absolute;
  top: 40%;
  right: 0%;
  width: 100%;
  height: 140%;
  transform: translate(55%, -50%);
  transform-origin: center center;
  stroke: var(--color-yellow);
  stroke-width: 4px;
  pointer-events: none;
  z-index: -1;
}
.svg-both u .svg-2 {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 100%;
  height: 80%;
  transform: translate(-50%, 0%);
  stroke: var(--color-yellow);
  stroke-width: 3px;
  pointer-events: none;
  transform-origin: center center;
  z-index: -1;
}
.svg-both u path {
  stroke-dashoffset: 0;
  stroke-dasharray: none;
}

.svg-sun-3-fix-2 u svg {
  top: calc(50% + 5px);
}

.content-width-1 {
  width: 100%;
  max-width: 600px;
}

.content-width-2 {
  width: 100%;
  max-width: 720px;
}

.content-width-3 {
  width: 100%;
  max-width: 960px;
}

.hover-zoom.shape-top-arc .hover-zoom__zoom, .hover-zoom.shape-top-arc .hover-zoom__inner {
  transform-origin: center bottom;
}
.hover-zoom.shape-bottom-arc .hover-zoom__zoom, .hover-zoom.shape-bottom-arc .hover-zoom__inner {
  transform-origin: center top;
}

.hover-zoom:hover:not(:focus) .hover-zoom__zoom {
  transform: scale(0.98);
}
.hover-zoom:hover:not(:focus) .hover-zoom__inner {
  transform: scale(1.02);
}

.hover-zoom-hovered .hover-zoom__zoom {
  transform: scale(0.98);
}
.hover-zoom-hovered .hover-zoom__inner {
  transform: scale(1.02);
}
.hover-zoom-hovered a, .hover-zoom-hovered a * {
  color: var(--color-accent);
}

.hover-zoom__inner {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.hover-zoom__zoom, .hover-zoom__inner {
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.4s ease-in-out;
}

.canvas-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 0;
}

.has-curtains img, .has-curtains video {
  visibility: hidden;
  opacity: 0;
}

.no-curtains .canvas-wrapper {
  opacity: 0;
  visibility: hidden;
}

.canvas-wrapper ~ img, .canvas-wrapper ~ video {
  visibility: hidden;
  opacity: 0;
}

.custom-modal {
  --duracion: .25s;
  --offset: 0, 50px;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100%;
  display: none;
  background: var(--color-dark-1);
  overflow-y: auto;
}
.custom-modal .container {
  padding: 0px 20px;
  display: flex;
  min-height: 100%;
}
@media screen and (max-width: 767px) {
  .custom-modal .container img {
    max-width: 260px !important;
  }
}
.custom-modal .container .modal-center {
  display: flex;
  align-items: center;
  width: 100%;
}
.custom-modal .container .bg-content-white {
  position: relative;
  width: 100%;
  padding-top: calc(1 * 20 * 1px);
  padding-bottom: calc(1 * 40 * 1px);
}
@media screen and (min-width: 360px) {
  .custom-modal .container .bg-content-white {
    padding-top: calc(1 * (20 * 1px + (100 - 20) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .container .bg-content-white {
    padding-top: calc(1 * 100 * 1px);
  }
}
@media screen and (min-width: 360px) {
  .custom-modal .container .bg-content-white {
    padding-bottom: calc(1 * (40 * 1px + (60 - 40) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .container .bg-content-white {
    padding-bottom: calc(1 * 60 * 1px);
  }
}
.custom-modal.show {
  display: block;
  animation: show-modal var(--duracion) ease forwards;
}
.custom-modal.show section {
  animation: reset-translate var(--duracion) ease forwards;
}
.custom-modal.show.hidde {
  animation: hidde-modal var(--duracion) ease forwards;
}
.custom-modal.show.hidde section {
  animation: set-translate var(--duracion) ease forwards;
}
.custom-modal .close-btn {
  position: absolute;
  font-size: 3rem;
  right: calc(1 * 20 * 1px);
  top: calc(1 * 20 * 1px);
  background: none;
  border: none;
  appearance: none;
  color: var(--color-light-1);
  z-index: 99;
  cursor: pointer;
}
@media screen and (min-width: 360px) {
  .custom-modal .close-btn {
    right: calc(1 * (20 * 1px + (30 - 20) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .close-btn {
    right: calc(1 * 30 * 1px);
  }
}
@media screen and (min-width: 360px) {
  .custom-modal .close-btn {
    top: calc(1 * (20 * 1px + (30 - 20) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .custom-modal .close-btn {
    top: calc(1 * 30 * 1px);
  }
}
@media screen and (max-width: 991px) {
  .custom-modal .close-btn {
    font-size: 2.5rem;
  }
}

@keyframes show-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes reset-translate {
  from {
    transform: translate(var(--offset));
  }
  to {
    transform: translate(0);
  }
}
@keyframes hidde-modal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.curtain-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  z-index: -1;
  pointer-events: none;
}

.header {
  position: static;
  --header-min-gutters-horizontal: 20;
  --header-max-gutters-horizontal: 50;
  --header-min-gutters-vertical: 15;
  --header-max-gutters-vertical: 20;
}
.header a, .header select, .header input {
  pointer-events: initial;
}

.header__border-horizontal {
  transform-origin: left center;
  transition: all 0.3s ease;
}

.header__border-vertical {
  transform-origin: top center;
  transition: all 0.3s ease;
}

.header__label {
  position: relative;
  white-space: nowrap;
  transition: transform 0.3s ease;
}

.header__label-hover {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s ease;
  color: currentColor;
}

.header__label-normal {
  position: relative;
  transform: translateY(0%);
  transition: all 0.3s ease;
}

.header__arrow-back {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header__arrow-back-line {
  position: relative;
  width: 32px;
  height: 2px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  clip-path: inset(0px);
}
.header__arrow-back-line:nth-of-type(1) {
  width: 20px;
  transform: rotate(-40deg) translate(-18px, 9px);
  transform-origin: left center;
}
.header__arrow-back-line:nth-of-type(1):before, .header__arrow-back-line:nth-of-type(1):after {
  transition-delay: 50ms;
}
.header__arrow-back-line:nth-of-type(2) {
  margin-top: auto;
  margin-bottom: auto;
}
.header__arrow-back-line:nth-of-type(2):before, .header__arrow-back-line:nth-of-type(2):after {
  transition-delay: 0ms;
}
.header__arrow-back-line:nth-of-type(3) {
  width: 20px;
  transform: rotate(40deg) translate(-18px, -9px);
  transform-origin: left center;
}
.header__arrow-back-line:nth-of-type(3):before, .header__arrow-back-line:nth-of-type(3):after {
  transition-delay: 50ms;
}
.header__arrow-back-line:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  background-color: currentColor;
  transition: all 0.3s ease;
}
.header__arrow-back-line:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 4px));
  background-color: currentColor;
  transition: all 0.5s ease;
}

.header__bar {
  display: flex;
  z-index: 500;
  pointer-events: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
}

.header__bar_absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header__bar_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__bar_relative {
  position: relative !important;
}

.header__bar[data-arts-header-sticky-class] {
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0);
}

.header__bar_sticky[data-arts-header-sticky-class]:not(.header__bar_scrolling-down):not(.ajax-loading .header__bar_sticky) {
  border-color: transparent;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.04);
}
.header__bar_sticky[data-arts-header-sticky-class]:not(.header__bar_scrolling-down):not(.ajax-loading .header__bar_sticky) .header__border-horizontal {
  background-color: transparent;
}

.header__bar_scrolling-down, .ajax-loading .header__bar_sticky {
  background-color: transparent !important;
}

.header__bar_sticky {
  translate: none;
  rotate: none;
  scale: none;
  transform: translate3d(0, var(--translateY), 0);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
}

.header__bar_sticky .logo__wrapper-img img {
  width: calc(1 * var(--logo-min-width)/1.3 * 1px);
}
@media screen and (min-width: 360px) {
  .header__bar_sticky .logo__wrapper-img img {
    width: calc(1 * (var(--logo-min-width)/1.3 * 1px + (var(--logo-max-width)/1.3 - var(--logo-min-width)/1.3) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .header__bar_sticky .logo__wrapper-img img {
    width: calc(1 * var(--logo-max-width)/1.3 * 1px);
  }
}
.header__bar_sticky .header__col {
  padding-top: calc(1 * var(--header-min-gutters-vertical)/1.5 * 1px);
  padding-bottom: calc(1 * var(--header-min-gutters-vertical)/1.5 * 1px);
}
@media screen and (min-width: 360px) {
  .header__bar_sticky .header__col {
    padding-top: calc(1 * (var(--header-min-gutters-vertical)/1.5 * 1px + (var(--header-max-gutters-vertical)/1.5 - var(--header-min-gutters-vertical)/1.5) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .header__bar_sticky .header__col {
    padding-top: calc(1 * var(--header-max-gutters-vertical)/1.5 * 1px);
  }
}
@media screen and (min-width: 360px) {
  .header__bar_sticky .header__col {
    padding-bottom: calc(1 * (var(--header-min-gutters-vertical)/1.5 * 1px + (var(--header-max-gutters-vertical)/1.5 - var(--header-min-gutters-vertical)/1.5) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .header__bar_sticky .header__col {
    padding-bottom: calc(1 * var(--header-max-gutters-vertical)/1.5 * 1px);
  }
}

.header:not(.opened) .header__label-burger_closed-hover, .header:not(.opened) .header__label-burger_opened-open, .header:not(.opened) .header__label-burger_opened-hover {
  transform: translateY(100%);
  opacity: 0;
}
.header:not(.opened) .header__burger:hover .header__label-burger_closed-open {
  transform: translateY(-100%);
  opacity: 0;
}
.header:not(.opened) .header__burger:hover .header__label-burger_closed-hover {
  transform: translateY(0%);
  opacity: 1;
}
.header.opened .header__label-burger_closed-open, .header.opened .header__label-burger_closed-hover, .header.opened .header__label-burger_opened-hover {
  transform: translateY(100%);
  opacity: 0;
}
.header.opened .header__burger:hover .header__label-burger_opened-open {
  transform: translateY(-100%);
  opacity: 0;
}
.header.opened .header__burger:hover .header__label-burger_opened-hover {
  transform: translateY(0%);
  opacity: 1;
}

.header__burger-wrapper-lines {
  position: relative;
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}

.header__burger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  pointer-events: initial;
  z-index: 500;
  background: none;
  outline: none;
}
.header__burger:hover .header__burger-line:before {
  transform: translateX(100%);
}
.header__burger:hover .header__burger-line:after {
  transform: translateX(0%);
}

.header__burger-label {
  display: inline-block;
  line-height: 1;
  position: relative;
  text-align: right;
  transition: transform 0.3s ease;
  color: currentColor;
}

.header__label-burger_inner {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease;
}

.header__label-burger_closed-open {
  position: relative;
}

.header__burger-line {
  position: relative;
  width: calc(1 * 30 * 1px);
  height: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 360px) {
  .header__burger-line {
    width: calc(1 * (30 * 1px + (40 - 30) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .header__burger-line {
    width: calc(1 * 40 * 1px);
  }
}
.header__burger-line:nth-of-type(1):before, .header__burger-line:nth-of-type(1):after {
  transition-delay: 0ms;
}
.header__burger-line:nth-of-type(2):before, .header__burger-line:nth-of-type(2):after {
  transition-delay: 50ms;
}
.header__burger-line:nth-of-type(3):before, .header__burger-line:nth-of-type(3):after {
  transition-delay: 100ms;
}
.header__burger-line:first-of-type {
  margin-top: auto;
  margin-bottom: 2px;
}
.header__burger-line:last-of-type {
  margin-top: 2px;
  margin-bottom: auto;
}
.header__burger-line:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  background-color: currentColor;
  transition: transform 0.2s ease;
}
.header__burger-line:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 4px));
  background-color: currentColor;
  transition: transform 0.4s ease;
}

.header__burger_opened .header__burger-line:nth-of-type(1), .header__burger_opened:hover .header__burger-line:nth-of-type(1) {
  transform: scaleX(1) rotate(45deg) translate(2px, 2px);
}
.header__burger_opened .header__burger-line:nth-of-type(2), .header__burger_opened:hover .header__burger-line:nth-of-type(2) {
  transform: scaleX(1) rotate(-45deg) translate(2px, -2px);
}
.header__burger_opened .header__burger-line:nth-of-type(3), .header__burger_opened:hover .header__burger-line:nth-of-type(3) {
  transform-origin: right center;
  transform: scaleX(0);
}

.header__burger_opened .header__label-burger_closed-open, .header__burger_opened .header__label-burger_closed-hover {
  transform: translateY(-100%) !important;
  opacity: 0 !important;
}

.opened .header__burger-line {
  color: #fff;
}

.header__overlay-menu-back {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  pointer-events: auto;
  z-index: 500;
  transform: translateY(-30px);
  transition: all 0.3s ease;
}
.header__overlay-menu-back:hover .header__arrow-back-line:before {
  transform: translateX(100%);
}
.header__overlay-menu-back:hover .header__arrow-back-line:after {
  transform: translateX(0%);
}
.header__overlay-menu-back:hover .header__label_opened-back {
  transform: translateY(-100%);
  opacity: 0;
}
.header__overlay-menu-back:hover .header__label_opened-hover {
  transform: translateY(0%);
  opacity: 1;
}

.has-cursor-follower .header__overlay-menu-back[data-arts-cursor-follower-target]:hover .header__label {
  transform: translateX(20px);
}

.header__label_opened-hover {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s ease;
  color: currentColor;
}

.header__label_opened-back {
  position: relative;
  transform: translateY(0%);
  transition: all 0.3s ease;
}

.header__col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: calc(1 * var(--header-min-gutters-horizontal) * 1px);
  padding-right: calc(1 * var(--header-min-gutters-horizontal) * 1px);
  padding-top: calc(1 * var(--header-min-gutters-vertical) * 1px);
  padding-bottom: calc(1 * var(--header-min-gutters-vertical) * 1px);
  transition: all 0.3s ease;
  z-index: 500;
}
@media screen and (min-width: 360px) {
  .header__col {
    padding-left: calc(1 * (var(--header-min-gutters-horizontal) * 1px + (var(--header-max-gutters-horizontal) - var(--header-min-gutters-horizontal)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .header__col {
    padding-left: calc(1 * var(--header-max-gutters-horizontal) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .header__col {
    padding-right: calc(1 * (var(--header-min-gutters-horizontal) * 1px + (var(--header-max-gutters-horizontal) - var(--header-min-gutters-horizontal)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .header__col {
    padding-right: calc(1 * var(--header-max-gutters-horizontal) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .header__col {
    padding-top: calc(1 * (var(--header-min-gutters-vertical) * 1px + (var(--header-max-gutters-vertical) - var(--header-min-gutters-vertical)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .header__col {
    padding-top: calc(1 * var(--header-max-gutters-vertical) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .header__col {
    padding-bottom: calc(1 * (var(--header-min-gutters-vertical) * 1px + (var(--header-max-gutters-vertical) - var(--header-min-gutters-vertical)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .header__col {
    padding-bottom: calc(1 * var(--header-max-gutters-vertical) * 1px);
  }
}
.header__col:hover .header__label-normal {
  transform: translateY(-100%);
  opacity: 0;
}
.header__col:hover .header__label-hover {
  transform: translateY(0%);
  opacity: 1;
}

.has-cursor-follower .header__col_left[data-arts-cursor-follower-target]:hover .header__label {
  transform: translateX(20px);
}
.has-cursor-follower .header__col_right[data-arts-cursor-follower-target]:hover .header__label, .has-cursor-follower .header__col_center[data-arts-cursor-follower-target]:hover .header__label {
  transform: translateX(-20px);
}

.header__col-overlay, .header__col-widgets {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.header__col-overlay {
  padding-left: var(--gutter-horizontal);
  padding-right: calc(var(--gutter-horizontal) / 2);
}

.header__col-widgets {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-left: 1px solid var(--color-border-opacity);
  padding-left: calc(var(--gutter-horizontal) / 2);
  padding-right: var(--gutter-horizontal);
  padding-bottom: var(--gutter-vertical);
}

.header__col_left {
  text-align: left;
}

.header__col_center {
  text-align: center;
}

.header__col_right {
  text-align: right;
}

@media screen and (max-width: 1200px) {
  .header__col-overlay, .header__col-widgets {
    justify-content: flex-start;
    padding-left: var(--gutter-horizontal);
    padding-right: var(--gutter-horizontal);
    height: auto;
  }
  .header__col-overlay {
    padding-top: calc(var(--gutter-vertical) + var(--header-height));
    padding-bottom: calc(var(--gutter-vertical) * 2);
  }
  .header__col-widgets {
    padding-top: calc(var(--gutter-vertical) * 2);
    padding-bottom: calc(var(--gutter-vertical) * 2);
    border-left: none;
    border-top: 1px solid var(--color-border-opacity);
  }
  .header__col_md-border-left {
    border-left: none;
  }
  .header__col_md-border-right {
    border-right: none;
  }
}
.pt-header-height {
  padding-top: var(--header-height);
}

.pb-header-height {
  padding-bottom: var(--header-height);
}

.py-header-height {
  padding-top: var(--header-height);
  padding-bottom: var(--header-height);
}

.header-height {
  height: var(--header-height);
}

.mt-header-height {
  margin-top: var(--header-height);
}

.mb-header-height {
  margin-bottom: var(--header-height);
}

.my-header-height {
  margin-top: var(--header-height);
  margin-bottom: var(--header-height);
}

.header_classic-menu-right .menu-classic .sub-menu {
  left: auto;
  right: 1em;
}
.header_classic-menu-right .menu-classic .sub-menu ul {
  left: auto;
  right: calc(100% + 1px);
}

.header_classic-menu-split-center .header__col_classic-right .menu-classic .sub-menu {
  left: auto;
  right: 1em;
}
.header_classic-menu-split-center .header__col_classic-right .menu-classic .sub-menu ul {
  left: auto;
  right: calc(100% + 1px);
}

.header_overlay-logo-center-burger-left .header__overlay-menu-back {
  top: var(--header-height);
}
@media screen and (max-width: 991px) {
  .header_overlay-logo-center-burger-left .sub-menu-holder {
    top: var(--header-height);
  }
}

[data-arts-header-logo=primary] .logo__img-primary {
  opacity: 1;
  visibility: visible;
}
[data-arts-header-logo=primary] .logo__img-secondary {
  opacity: 0;
  visibility: hidden;
}

[data-arts-header-logo=secondary] .logo__img-primary {
  opacity: 0;
  visibility: hidden;
}
[data-arts-header-logo=secondary] .logo__img-secondary {
  opacity: 1;
  visibility: visible;
}

.header.opened .header__col {
  border-color: transparent;
}
.header.opened .header__col_logo, .header.opened .header__col_additional {
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
}
.header.opened .header__wrapper-overlay-menu {
  display: block;
  pointer-events: auto;
}
.header.opened .header__border-horizontal {
  transform: scaleX(0);
}
.header.opened .header__border-vertical {
  transform: scaleY(0);
}
.header.opened.opened-submenu .header__overlay-menu-back, .header.opened.opened-submenu .header__overlay-submenu-status {
  pointer-events: initial;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.header.opened.opened-submenu .menu-overlay__counter {
  border-color: transparent;
}
.header.animating .header__wrapper-overlay-menu *:not(.header__overlay-menu-back) {
  pointer-events: none !important;
}
.header.animating .menu-overlay__counter {
  border-color: transparent;
}

.header:not(.opened) .header__wrapper-overlay-menu {
  pointer-events: none !important;
}
.header:not(.opened) .header__wrapper-overlay-menu * {
  pointer-events: none !important;
}

.header__wrapper-overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.header_hidden-not-opened:not(.opened) {
  opacity: 0;
  visibility: hidden;
}

.header__overlay-inner {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .header:not(.opened-submenu) .header__overlay-inner {
    overflow-x: hidden;
    overflow-y: overlay;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
  }
}
.header__overlay-submenu-status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  padding-right: calc(var(--gutter-horizontal) / 2);
  pointer-events: none !important;
  z-index: 500;
  transform: translateY(-30px);
  transition: all 0.3s ease;
}

.header__submenu-label {
  display: inline-block;
  line-height: 1;
  height: 40px;
  text-align: right;
  transition: transform 0.3s ease;
}

.header__label_opened-next {
  position: absolute;
  top: calc(50% - 8px);
  transform: translate(-50%, 100%);
  right: 0;
  opacity: 0;
  color: currentColor;
}

.header__label_opened-current {
  position: absolute;
  top: calc(50% - 8px);
  transform: translate(-50%, -50%);
  right: 0;
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .header__overlay-submenu-status {
    left: 0;
    right: 0;
    text-align: center;
    padding-right: var(--gutter-horizontal);
  }
}
@media screen and (max-width: 767px) {
  .header__overlay-submenu-status {
    display: none;
    top: auto;
    bottom: 0;
  }
}
.header__widget {
  margin-bottom: var(--gutter-vertical);
}
.header__widget:last-child {
  margin-bottom: 0;
}

.header__widget-title {
  margin-bottom: 0.5em;
}

.header__widget-content {
  line-height: 1.8;
}

.footer__column-pt-small {
  padding-top: calc(1 * 30 * 1px);
}
@media screen and (min-width: 360px) {
  .footer__column-pt-small {
    padding-top: calc(1 * (30 * 1px + (30 - 30) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .footer__column-pt-small {
    padding-top: calc(1 * 30 * 1px);
  }
}

.footer__column-pt-medium {
  padding-top: calc(1 * 80 * 1px);
}
@media screen and (min-width: 360px) {
  .footer__column-pt-medium {
    padding-top: calc(1 * (80 * 1px + (140 - 80) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .footer__column-pt-medium {
    padding-top: calc(1 * 140 * 1px);
  }
}

.footer__row-pb-small {
  padding-bottom: calc(1 * 30 * 1px);
}
@media screen and (min-width: 360px) {
  .footer__row-pb-small {
    padding-bottom: calc(1 * (30 * 1px + (30 - 30) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .footer__row-pb-small {
    padding-bottom: calc(1 * 30 * 1px);
  }
}

.footer__row-pb-medium {
  padding-bottom: calc(1 * 80 * 1px);
}
@media screen and (min-width: 360px) {
  .footer__row-pb-medium {
    padding-bottom: calc(1 * (80 * 1px + (140 - 80) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .footer__row-pb-medium {
    padding-bottom: calc(1 * 140 * 1px);
  }
}

.fw-200, .fw-200 p {
  font-weight: 200 !important;
}

.fw-300, .fw-300 p {
  font-weight: 300 !important;
}

.fw-400, .fw-400 p {
  font-weight: 400 !important;
}

.fw-500, .fw-500 p {
  font-weight: 500 !important;
}

.fw-600, .fw-600 p {
  font-weight: 600 !important;
}

.fw-700, .fw-700 p {
  font-weight: 700 !important;
}

.fw-800, .fw-800 p {
  font-weight: 800 !important;
}

.h-200 {
  max-height: 100vh;
  height: 200px;
}

.h-300 {
  max-height: 100vh;
  height: 300px;
}

.h-400 {
  max-height: 100vh;
  height: 400px;
}

.h-500 {
  max-height: 100vh;
  height: 500px;
}

.h-600 {
  max-height: 100vh;
  height: 600px;
}

.h-700 {
  max-height: 100vh;
  height: 700px;
}

.h-800 {
  max-height: 100vh;
  height: 800px;
}

.h-900 {
  max-height: 100vh;
  height: 900px;
}

.h-1000 {
  max-height: 100vh;
  height: 1000px;
}

@media screen and (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
}
.lh-1-1 {
  line-height: 1.1 !important;
}

.lh-1-2 {
  line-height: 1.2 !important;
}

.lh-1-3 {
  line-height: 1.3 !important;
}

.of-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.of-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.full-height {
  position: relative !important;
  display: inline-block !important;
  align-items: center !important;
  justify-content: center !important;
  width: auto !important;
  height: 100% !important;
  max-width: unset !important;
}

.hs-full-height {
  width: 100%;
  height: auto;
}

.auto-width-height {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.full-width {
  width: 100% !important;
  height: auto !important;
}

.of-cover-absolute {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.op-top-center {
  object-position: top center;
}

.op-right-center {
  object-position: right center;
}

.op-bottom-center {
  object-position: bottom center;
}

.op-left-center {
  object-position: left center;
}

.overflow-hidden {
  overflow: hidden;
  clip-path: inset(0px);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  --overlay-gradient-opacity-start: 1;
  --overlay-gradient-opacity-end: 0.25;
  --overlay-opacity: 0;
  background-color: rgba(var(--overlay-rgb), var(--overlay-opacity));
  transform: translateZ(0);
}

.overlay_dark {
  --overlay-rgb: 0, 0, 0;
}

.overlay_light {
  --overlay-rgb: 255, 255, 255;
}

/** Themed overlays **/
.overlay_theme-from-top-to-bottom {
  background-image: linear-gradient(180deg, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-start)) 0%, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-end)) 100%);
}

.overlay_theme-from-bottom-to-top {
  background-image: linear-gradient(0deg, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-start)) 0%, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-end)) 100%);
}

.overlay_theme-from-left-to-right {
  background-image: linear-gradient(90deg, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-start)) 0%, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-end)) 100%);
}

.overlay_theme-from-right-to-left {
  background-image: linear-gradient(270deg, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-start)) 0%, rgba(var(--overlay-rgb), var(--overlay-gradient-opacity-end)) 100%);
}

.overlay_opacity-10 {
  --overlay-opacity: 0.1;
}

.overlay_opacity-20 {
  --overlay-opacity: 0.2;
}

.overlay_opacity-30 {
  --overlay-opacity: 0.3;
}

.overlay_opacity-40 {
  --overlay-opacity: 0.4;
}

.overlay_opacity-50 {
  --overlay-opacity: 0.5;
}

.overlay_opacity-60 {
  --overlay-opacity: 0.6;
}

.overlay_opacity-70 {
  --overlay-opacity: 0.7;
}

.overlay_opacity-80 {
  --overlay-opacity: 0.8;
}

.overlay_opacity-90 {
  --overlay-opacity: 0.9;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-40 {
  width: 40% !important;
}

@media screen and (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-33 {
    width: 33% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-66 {
    width: 66% !important;
  }
}
.z-minus-1 {
  z-index: -1 !important;
}

.z-100 {
  z-index: 100 !important;
}

.z-50 {
  z-index: 50 !important;
}

.z-40 {
  z-index: 40 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-0 {
  z-index: 0 !important;
}

.lazy {
  transition: background-color 0.1s ease;
}

.lazy:not(.loaded):not(.entered) {
  background-color: rgba(136, 136, 136, 0.1);
}

video.lazy:not(.error) + img.lazy {
  opacity: 0;
  visibility: hidden;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: -1;
  }
  50% {
    stroke-dasharray: 75, 200;
    stroke-dashoffset: -75;
  }
  100% {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -150;
  }
}
.logo {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.logo__wrapper-img {
  position: relative;
  align-items: center;
  flex: 1 0 auto;
}
.logo__wrapper-img img {
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  display: block;
  width: calc(1 * var(--logo-min-width) * 1px);
}
@media screen and (min-width: 360px) {
  .logo__wrapper-img img {
    width: calc(1 * (var(--logo-min-width) * 1px + (var(--logo-max-width) - var(--logo-min-width)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .logo__wrapper-img img {
    width: calc(1 * var(--logo-max-width) * 1px);
  }
}

.logo__text-title {
  display: block;
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  color: var(--paragraph-color);
}

.logo__text-tagline {
  display: block;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3;
}

.logo__img-secondary {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.logo__description {
  margin-top: 1em;
}

.js-mask__clone_has-color * {
  color: inherit !important;
}

.masthead__overlay {
  z-index: 10;
}

.masthead__scroll-down {
  display: inline-block;
  position: absolute;
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  bottom: calc(var(--gutter-horizontal-min) * 1px);
  z-index: 100;
}
@media screen and (min-width: 992px) {
  .masthead__scroll-down.offset-lg-6 {
    margin-left: calc(50% - var(--gutter-horizontal));
  }
}

.masthead__scroll-down_left {
  left: var(--gutter-horizontal);
  right: auto;
}

.masthead__scroll-down_right {
  left: auto;
  right: var(--gutter-horizontal);
}

.masthead__header {
  position: relative;
  z-index: 50;
}

.masthead__footer {
  position: absolute;
  bottom: var(--gutter-vertical);
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  z-index: 50;
}

.masthead__background-full-height {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-height: 100%;
  width: auto;
  z-index: 0;
}

.masthead__background-full-height_left {
  left: 0;
  right: auto;
}

.masthead__background-full-height_center {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.masthead__background-full-height_right {
  left: auto;
  right: 0;
}

.masthead__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  z-index: 0;
}

.masthead__background-top-space {
  position: absolute;
  top: calc(var(--header-height) + var(--gutter-vertical) / 2);
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-width: 100%;
  max-height: calc(100% - var(--header-height) - var(--gutter-vertical) / 2);
}

.masthead__background_right {
  margin-left: auto;
}

.masthead__media {
  position: relative;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .masthead__background_right {
    right: var(--gutter-horizontal);
  }
}
@media screen and (max-width: 1024px) {
  .masthead__background-mobile {
    height: 50vh;
  }
  .masthead__scroll-down_mobile-center {
    left: var(--gutter-horizontal);
    right: var(--gutter-horizontal);
    text-align: center;
  }
}
.masthead-1__background {
  position: absolute;
  top: calc(var(--header-height) + var(--gutter-vertical));
  left: var(--gutter-horizontal);
  right: var(--gutter-horizontal);
  bottom: 0;
  height: 100%;
  max-width: calc(100% - var(--gutter-horizontal) * 2);
  max-height: calc(100% - var(--header-height) - var(--gutter-vertical) * 2);
}

.masthead-1__header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: calc(var(--header-height) + var(--gutter-vertical) * 2);
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
  padding-bottom: calc(var(--gutter-vertical) * 2);
  margin: 0;
  z-index: 50;
}

.masthead-1__heading-top {
  display: block;
  width: 100%;
  max-width: calc(50vw - var(--gutter-horizontal));
  text-align: right;
}

.masthead-1__heading-bottom {
  display: block;
  width: 100%;
  max-width: calc(50vw - var(--gutter-horizontal));
  margin-left: auto;
  text-align: left;
}

.masthead-1__description {
  width: 100%;
  max-width: calc(50% - var(--gutter-horizontal));
  z-index: 60;
}

@media screen and (max-width: 991px) {
  .masthead-1__header {
    justify-content: flex-start;
  }
  .masthead-1__heading-top, .masthead-1__heading-bottom {
    max-width: 100%;
    text-align: left;
  }
  .masthead-1__description {
    margin-top: auto;
    text-align: right;
    margin-left: auto;
  }
}
.masthead-2__background {
  position: relative;
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.masthead-2__wrapper-background {
  height: 100vh;
}

.masthead-2__header_absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.masthead-2__header {
  position: relative;
  z-index: 60;
}

@media screen and (max-width: 991px) {
  .masthead-2__wrapper-background {
    height: 66vh;
  }
}
@media screen and (min-width: 992px) {
  .masthead-2__header {
    margin-left: calc(-1 * var(--distance-min-xlarge) * 1px);
  }
}
@media screen and (min-width: 992px) and (min-width: 360px) {
  .masthead-2__header {
    margin-left: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .masthead-2__header {
    margin-left: calc(-1 * var(--distance-max-xlarge) * 1px);
  }
}
.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  line-height: 2;
}

.media__link:hover * {
  color: currentColor;
}

.media__caption {
  margin-top: 1em;
}

@media screen and (max-width: 991px) {
  .media__caption {
    margin-bottom: 0.5em;
  }
}
.menu-overlay {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.menu-overlay .sub-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: var(--header-height);
  padding-bottom: var(--header-height);
  visibility: hidden;
}
.menu-overlay .sub-menu > li {
  display: block;
}
.menu-overlay .sub-menu > li > a {
  display: inline-block;
  padding: 0.4rem 0;
}
.menu-overlay > li {
  display: block;
  overflow: hidden;
}
.menu-overlay > li > a {
  display: inline-flex;
  align-items: center;
  padding: 1rem 0;
}
.menu-overlay > li > a:hover .menu-overlay__counter {
  color: currentColor;
  border-color: currentColor;
}
.menu-overlay li.current-menu-item > a, .menu-overlay li.current-menu-ancestor > a {
  color: var(--color-accent);
}
.menu-overlay li.current-menu-item > a *, .menu-overlay li.current-menu-ancestor > a * {
  color: var(--color-accent);
}

.menu-overlay li a * {
  pointer-events: none;
}

.menu-overlay__heading {
  flex: 0 1 auto;
}

.menu-overlay__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  flex: 1 0 70px;
  border-radius: 100%;
  border: 1px solid var(--color-border-opacity);
  white-space: nowrap;
  transition: color 0.2s ease, border-color 0.2s ease;
}

.text-start .menu-overlay__counter {
  margin-right: calc(var(--gutter-horizontal) / 2);
  order: -1;
}

.text-end .menu-overlay__counter {
  margin-left: calc(var(--gutter-horizontal) / 2);
}

@media screen and (max-width: 991px) {
  .menu-overlay .sub-menu {
    padding-top: 0;
  }
  .menu-overlay__counter {
    width: 50px;
    height: 50px;
    flex: 1 0 50px;
  }
  .text-start .menu-overlay__counter {
    margin-right: calc(var(--gutter-horizontal));
  }
  .text-end .menu-overlay__counter {
    margin-left: calc(var(--gutter-horizontal));
  }
}
.sub-menu-holder {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.sub-menu-holder .sub-menu {
  width: 100%;
  height: 100%;
  z-index: 50;
}
.sub-menu-holder .sub-menu.current {
  pointer-events: auto;
}

@media screen and (max-width: 991px) {
  .sub-menu-holder .sub-menu {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    max-height: calc(var(--fix-bar-vh, 1vh) * 100);
    padding-bottom: calc(var(--header-height) * 2);
  }
}
.menu-classic {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: 0 -0.25em;
  font-weight: bold;
  font-size: 14px;
  line-height: 2;
}
.menu-classic > li:last-child:before {
  display: none;
}
.menu-classic > li:before {
  content: "";
  position: absolute;
  right: -50%;
  height: 24px;
  width: 1px;
  background: #000;
  display: block;
  top: 50%;
  transform: translateY(-12px);
}
.menu-classic > li {
  position: relative;
  display: inline-block;
}
.menu-classic > li:not(:last-child) {
  margin-right: calc(1 * var(--menu-min-margin-right) * 1px);
}
@media screen and (min-width: 360px) {
  .menu-classic > li:not(:last-child) {
    margin-right: calc(1 * (var(--menu-min-margin-right) * 1px + (var(--menu-max-margin-right) - var(--menu-min-margin-right)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .menu-classic > li:not(:last-child) {
    margin-right: calc(1 * var(--menu-max-margin-right) * 1px);
  }
}
.menu-classic > li a {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-transform: var(--menu-font-text-transform);
  font-weight: var(--menu-font-weight);
  letter-spacing: var(--menu-letter-spacing);
  color: var(--color-menu-links);
  font-size: calc(var(--menu-min-font-size) * 1px);
}
@media screen and (min-width: 360px) {
  .menu-classic > li a {
    font-size: calc(var(--menu-min-font-size) * 1px + (var(--menu-max-font-size) - var(--menu-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .menu-classic > li a {
    font-size: calc(var(--menu-max-font-size) * 1px);
  }
}
.menu-classic > li .active {
  font-weight: 600;
  transition: all 0.3s ease;
}
.menu-classic .active {
  transition: all 0.3s ease;
}
.menu-classic > li.menu-item-has-children > a:after {
  content: "+";
  display: inline-block;
  margin-left: 0.5em;
  transition: transform 0.3s ease;
  transform: rotate(0deg);
  transform-origin: center center;
}
.menu-classic > li.menu-item-has-children > a:hover:after {
  transform: rotate(-90deg);
}
.menu-classic .menu-item-has-children {
  position: relative;
}
.menu-classic .menu-item-has-children > a:hover ~ ul {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
  z-index: 50;
}
.menu-classic > li.current-menu-item > a {
  font-weight: bold;
}
.menu-classic > li.current-menu-ancestor > a {
  color: var(--color-accent);
}
.menu-classic > li.current-menu-ancestor .sub-menu li.current-menu-ancestor > a {
  color: var(--color-classic-submenu-links-hover);
}
.menu-classic > li.current-menu-ancestor .sub-menu li.current-menu-item > a {
  color: var(--color-classic-submenu-links-hover);
}
.menu-classic .sub-menu {
  position: absolute;
  top: 100%;
  left: 1em;
  transform: translate(0px, 1em);
  list-style-type: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
  background-color: #fff;
  border-radius: 4px;
  text-align: left;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.08);
}
.menu-classic .sub-menu:hover {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  z-index: 50;
}
.menu-classic .sub-menu > li {
  white-space: nowrap;
}
.menu-classic .sub-menu > li a {
  padding: 0.5em 1.25em;
  background-color: transparent;
  color: var(--color-classic-submenu-links);
}
.menu-classic .sub-menu > li a:hover {
  background-color: var(--color-classic-submenu-links-hover-background);
  color: var(--color-classic-submenu-links-hover);
}
.menu-classic .sub-menu > li:first-child a {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.menu-classic .sub-menu > li:last-child a {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.menu-classic .sub-menu ul {
  top: 0;
  left: calc(100% + 1px);
  transform: translate(0.5em, 0);
  opacity: 0;
  visibility: hidden;
}

.ajax-loading .menu-classic {
  pointer-events: auto;
}
.ajax-loading .menu-classic * {
  pointer-events: auto;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal__message {
  font-size: 18px;
}

.modal-content__wrapper-button {
  text-align: center;
}
.modal-content__wrapper-button .button {
  min-width: 200px;
}
.modal-content__wrapper-button .button * {
  pointer-events: none;
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 60;
}
.modal__close * {
  pointer-events: none;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-dialog_container {
  max-width: 980px !important;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
  overscroll-behavior: contain;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  outline: 0;
  padding: 3em;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
  .modal__message {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.properties__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.properties__list li {
  display: block;
}
.properties__list li:not(:last-child) {
  margin-bottom: 1rem;
}
.properties__list li:not(:first-child) {
  margin-top: 1rem;
}

.properties__name {
  margin-bottom: 1rem;
}

.parallax {
  display: flex;
  flex-direction: column;
  position: relative;
}

.parallax__link {
  display: inline-block;
  max-width: 100%;
}

.parallax__caption {
  margin-top: 1em;
}

@media screen and (max-width: 991px) {
  .parallax__caption {
    margin-bottom: 0.5em;
  }
}
.spinner {
  position: fixed;
  left: 30px;
  bottom: 30px;
  width: 30px;
  height: 30px;
  z-index: 10000;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  opacity: 0;
  visibility: hidden;
  color: var(--color-accent-light-theme);
}
.spinner circle {
  stroke: currentColor;
  stroke-width: 2px;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -150;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

.has-cursor-follower .spinner {
  display: none !important;
}

.spinner[data-arts-color-theme=dark], [data-arts-color-theme=dark] ~ .spinner {
  color: var(--color-accent-dark-theme);
}

.section {
  position: relative;
}

.section__content, .section__header {
  position: relative;
  z-index: 50;
}

.section__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
}

.section__overlay {
  z-index: 10;
  transition: opacity 0.3s ease;
  will-change: opacity;
}

.section_hovered .section__overlay {
  opacity: 0.7;
}

@media screen and (min-width: 992px) {
  .section__lg-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: 0;
  }
}
.section-fullheight {
  display: flex;
}

.section-fullheight__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  z-index: 50;
}

.section-fullheight__inner_mobile {
  height: calc(var(--fix-bar-vh, 1vh) * 100);
  min-height: 0vh;
}

@media screen and (max-width: 991px) {
  .section-fullheight__inner_mobile-auto {
    height: auto;
    display: block;
    min-height: 0;
  }
  .section-fullheight__inner_mobile-min-auto {
    height: auto;
    display: block;
    min-height: 100vh;
  }
}
.split-counter {
  display: inline-flex;
  align-items: flex-end;
}

.split-counter__lane {
  position: relative;
  overflow: hidden;
  display: inline-block;
  line-height: 1;
}
.split-counter__lane:nth-child(1) span {
  right: 0;
}
.split-counter__lane:nth-child(2) span {
  left: 0;
}
.split-counter__lane:nth-child(3) span {
  left: 0;
}

.m-xxsmall {
  margin: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .m-xxsmall {
    margin: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .m-xxsmall {
    margin: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.my-xxsmall {
  margin-top: calc(1 * var(--distance-min-xxsmall) * 1px);
  margin-bottom: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .my-xxsmall {
    margin-top: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-xxsmall {
    margin-top: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .my-xxsmall {
    margin-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-xxsmall {
    margin-bottom: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.mx-xxsmall {
  margin-left: calc(1 * var(--distance-min-xxsmall) * 1px);
  margin-right: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mx-xxsmall {
    margin-left: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xxsmall {
    margin-left: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .mx-xxsmall {
    margin-right: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xxsmall {
    margin-right: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.mt-xxsmall {
  margin-top: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-xxsmall {
    margin-top: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-xxsmall {
    margin-top: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.me-xxsmall {
  margin-right: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .me-xxsmall {
    margin-right: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-xxsmall {
    margin-right: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.mb-xxsmall {
  margin-bottom: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-xxsmall {
    margin-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-xxsmall {
    margin-bottom: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.ms-xxsmall {
  margin-left: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-xxsmall {
    margin-left: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-xxsmall {
    margin-left: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.mt-minus-xxsmall {
  margin-top: calc(-1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-minus-xxsmall {
    margin-top: calc(-1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-xxsmall {
    margin-top: calc(-1 * var(--distance-max-xxsmall) * 1px);
  }
}

.me-minus-xxsmall {
  margin-right: calc(-1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .me-minus-xxsmall {
    margin-right: calc(-1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-xxsmall {
    margin-right: calc(-1 * var(--distance-max-xxsmall) * 1px);
  }
}

.mb-minus-xxsmall {
  margin-bottom: calc(-1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-minus-xxsmall {
    margin-bottom: calc(-1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-xxsmall {
    margin-bottom: calc(-1 * var(--distance-max-xxsmall) * 1px);
  }
}

.ms-minus-xxsmall {
  margin-left: calc(-1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-minus-xxsmall {
    margin-left: calc(-1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-xxsmall {
    margin-left: calc(-1 * var(--distance-max-xxsmall) * 1px);
  }
}

.m-xsmall {
  margin: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .m-xsmall {
    margin: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .m-xsmall {
    margin: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.my-xsmall {
  margin-top: calc(1 * var(--distance-min-xsmall) * 1px);
  margin-bottom: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .my-xsmall {
    margin-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-xsmall {
    margin-top: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .my-xsmall {
    margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-xsmall {
    margin-bottom: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.mx-xsmall {
  margin-left: calc(1 * var(--distance-min-xsmall) * 1px);
  margin-right: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mx-xsmall {
    margin-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xsmall {
    margin-left: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .mx-xsmall {
    margin-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xsmall {
    margin-right: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.mt-xsmall {
  margin-top: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-xsmall {
    margin-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-xsmall {
    margin-top: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.me-xsmall {
  margin-right: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .me-xsmall {
    margin-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-xsmall {
    margin-right: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.mb-xsmall {
  margin-bottom: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-xsmall {
    margin-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-xsmall {
    margin-bottom: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.ms-xsmall {
  margin-left: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-xsmall {
    margin-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-xsmall {
    margin-left: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.mt-minus-xsmall {
  margin-top: calc(-1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-minus-xsmall {
    margin-top: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-xsmall {
    margin-top: calc(-1 * var(--distance-max-xsmall) * 1px);
  }
}

.me-minus-xsmall {
  margin-right: calc(-1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .me-minus-xsmall {
    margin-right: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-xsmall {
    margin-right: calc(-1 * var(--distance-max-xsmall) * 1px);
  }
}

.mb-minus-xsmall {
  margin-bottom: calc(-1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-minus-xsmall {
    margin-bottom: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-xsmall {
    margin-bottom: calc(-1 * var(--distance-max-xsmall) * 1px);
  }
}

.ms-minus-xsmall {
  margin-left: calc(-1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-minus-xsmall {
    margin-left: calc(-1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-xsmall {
    margin-left: calc(-1 * var(--distance-max-xsmall) * 1px);
  }
}

.m-small {
  margin: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .m-small {
    margin: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .m-small {
    margin: calc(1 * var(--distance-max-small) * 1px);
  }
}

.my-small {
  margin-top: calc(1 * var(--distance-min-small) * 1px);
  margin-bottom: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .my-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-small {
    margin-top: calc(1 * var(--distance-max-small) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .my-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-small {
    margin-bottom: calc(1 * var(--distance-max-small) * 1px);
  }
}

.mx-small {
  margin-left: calc(1 * var(--distance-min-small) * 1px);
  margin-right: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .mx-small {
    margin-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-small {
    margin-left: calc(1 * var(--distance-max-small) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .mx-small {
    margin-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-small {
    margin-right: calc(1 * var(--distance-max-small) * 1px);
  }
}

.mt-small {
  margin-top: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-small {
    margin-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-small {
    margin-top: calc(1 * var(--distance-max-small) * 1px);
  }
}

.me-small {
  margin-right: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .me-small {
    margin-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-small {
    margin-right: calc(1 * var(--distance-max-small) * 1px);
  }
}

.mb-small {
  margin-bottom: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-small {
    margin-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-small {
    margin-bottom: calc(1 * var(--distance-max-small) * 1px);
  }
}

.ms-small {
  margin-left: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-small {
    margin-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-small {
    margin-left: calc(1 * var(--distance-max-small) * 1px);
  }
}

.mt-minus-small {
  margin-top: calc(-1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-minus-small {
    margin-top: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-small {
    margin-top: calc(-1 * var(--distance-max-small) * 1px);
  }
}

.me-minus-small {
  margin-right: calc(-1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .me-minus-small {
    margin-right: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-small {
    margin-right: calc(-1 * var(--distance-max-small) * 1px);
  }
}

.mb-minus-small {
  margin-bottom: calc(-1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-minus-small {
    margin-bottom: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-small {
    margin-bottom: calc(-1 * var(--distance-max-small) * 1px);
  }
}

.ms-minus-small {
  margin-left: calc(-1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-minus-small {
    margin-left: calc(-1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-small {
    margin-left: calc(-1 * var(--distance-max-small) * 1px);
  }
}

.m-medium {
  margin: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .m-medium {
    margin: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .m-medium {
    margin: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.my-medium {
  margin-top: calc(1 * var(--distance-min-medium) * 1px);
  margin-bottom: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .my-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-medium {
    margin-top: calc(1 * var(--distance-max-medium) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .my-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-medium {
    margin-bottom: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.mx-medium {
  margin-left: calc(1 * var(--distance-min-medium) * 1px);
  margin-right: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .mx-medium {
    margin-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-medium {
    margin-left: calc(1 * var(--distance-max-medium) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .mx-medium {
    margin-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-medium {
    margin-right: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.mt-medium {
  margin-top: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-medium {
    margin-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-medium {
    margin-top: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.me-medium {
  margin-right: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .me-medium {
    margin-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-medium {
    margin-right: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.mb-medium {
  margin-bottom: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-medium {
    margin-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-medium {
    margin-bottom: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.ms-medium {
  margin-left: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-medium {
    margin-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-medium {
    margin-left: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.mt-minus-medium {
  margin-top: calc(-1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-minus-medium {
    margin-top: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-medium {
    margin-top: calc(-1 * var(--distance-max-medium) * 1px);
  }
}

.me-minus-medium {
  margin-right: calc(-1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .me-minus-medium {
    margin-right: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-medium {
    margin-right: calc(-1 * var(--distance-max-medium) * 1px);
  }
}

.mb-minus-medium {
  margin-bottom: calc(-1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-minus-medium {
    margin-bottom: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-medium {
    margin-bottom: calc(-1 * var(--distance-max-medium) * 1px);
  }
}

.ms-minus-medium {
  margin-left: calc(-1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-minus-medium {
    margin-left: calc(-1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-medium {
    margin-left: calc(-1 * var(--distance-max-medium) * 1px);
  }
}

.m-large {
  margin: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .m-large {
    margin: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .m-large {
    margin: calc(1 * var(--distance-max-large) * 1px);
  }
}

.my-large {
  margin-top: calc(1 * var(--distance-min-large) * 1px);
  margin-bottom: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .my-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-large {
    margin-top: calc(1 * var(--distance-max-large) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .my-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-large {
    margin-bottom: calc(1 * var(--distance-max-large) * 1px);
  }
}

.mx-large {
  margin-left: calc(1 * var(--distance-min-large) * 1px);
  margin-right: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .mx-large {
    margin-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-large {
    margin-left: calc(1 * var(--distance-max-large) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .mx-large {
    margin-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-large {
    margin-right: calc(1 * var(--distance-max-large) * 1px);
  }
}

.mt-large {
  margin-top: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-large {
    margin-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-large {
    margin-top: calc(1 * var(--distance-max-large) * 1px);
  }
}

.me-large {
  margin-right: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .me-large {
    margin-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-large {
    margin-right: calc(1 * var(--distance-max-large) * 1px);
  }
}

.mb-large {
  margin-bottom: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-large {
    margin-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-large {
    margin-bottom: calc(1 * var(--distance-max-large) * 1px);
  }
}

.ms-large {
  margin-left: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-large {
    margin-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-large {
    margin-left: calc(1 * var(--distance-max-large) * 1px);
  }
}

.mt-minus-large {
  margin-top: calc(-1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-minus-large {
    margin-top: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-large {
    margin-top: calc(-1 * var(--distance-max-large) * 1px);
  }
}

.me-minus-large {
  margin-right: calc(-1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .me-minus-large {
    margin-right: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-large {
    margin-right: calc(-1 * var(--distance-max-large) * 1px);
  }
}

.mb-minus-large {
  margin-bottom: calc(-1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-minus-large {
    margin-bottom: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-large {
    margin-bottom: calc(-1 * var(--distance-max-large) * 1px);
  }
}

.ms-minus-large {
  margin-left: calc(-1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-minus-large {
    margin-left: calc(-1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-large {
    margin-left: calc(-1 * var(--distance-max-large) * 1px);
  }
}

.m-xlarge {
  margin: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .m-xlarge {
    margin: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .m-xlarge {
    margin: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.my-xlarge {
  margin-top: calc(1 * var(--distance-min-xlarge) * 1px);
  margin-bottom: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .my-xlarge {
    margin-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-xlarge {
    margin-top: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .my-xlarge {
    margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .my-xlarge {
    margin-bottom: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.mx-xlarge {
  margin-left: calc(1 * var(--distance-min-xlarge) * 1px);
  margin-right: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .mx-xlarge {
    margin-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xlarge {
    margin-left: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .mx-xlarge {
    margin-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mx-xlarge {
    margin-right: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.mt-xlarge {
  margin-top: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-xlarge {
    margin-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-xlarge {
    margin-top: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.me-xlarge {
  margin-right: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .me-xlarge {
    margin-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-xlarge {
    margin-right: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.mb-xlarge {
  margin-bottom: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-xlarge {
    margin-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-xlarge {
    margin-bottom: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.ms-xlarge {
  margin-left: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-xlarge {
    margin-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-xlarge {
    margin-left: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.mt-minus-xlarge {
  margin-top: calc(-1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .mt-minus-xlarge {
    margin-top: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mt-minus-xlarge {
    margin-top: calc(-1 * var(--distance-max-xlarge) * 1px);
  }
}

.me-minus-xlarge {
  margin-right: calc(-1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .me-minus-xlarge {
    margin-right: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .me-minus-xlarge {
    margin-right: calc(-1 * var(--distance-max-xlarge) * 1px);
  }
}

.mb-minus-xlarge {
  margin-bottom: calc(-1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .mb-minus-xlarge {
    margin-bottom: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .mb-minus-xlarge {
    margin-bottom: calc(-1 * var(--distance-max-xlarge) * 1px);
  }
}

.ms-minus-xlarge {
  margin-left: calc(-1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .ms-minus-xlarge {
    margin-left: calc(-1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ms-minus-xlarge {
    margin-left: calc(-1 * var(--distance-max-xlarge) * 1px);
  }
}

.p-xxsmall {
  padding: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .p-xxsmall {
    padding: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .p-xxsmall {
    padding: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.py-xxsmall {
  padding-top: calc(1 * var(--distance-min-xxsmall) * 1px);
  padding-bottom: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .py-xxsmall {
    padding-top: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-xxsmall {
    padding-top: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .py-xxsmall {
    padding-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-xxsmall {
    padding-bottom: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.px-xxsmall {
  padding-left: calc(1 * var(--distance-min-xxsmall) * 1px);
  padding-right: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .px-xxsmall {
    padding-left: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-xxsmall {
    padding-left: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .px-xxsmall {
    padding-right: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-xxsmall {
    padding-right: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.pt-xxsmall {
  padding-top: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .pt-xxsmall {
    padding-top: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pt-xxsmall {
    padding-top: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.pe-xxsmall {
  padding-right: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .pe-xxsmall {
    padding-right: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pe-xxsmall {
    padding-right: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.pb-xxsmall {
  padding-bottom: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .pb-xxsmall {
    padding-bottom: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pb-xxsmall {
    padding-bottom: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.ps-xxsmall {
  padding-left: calc(1 * var(--distance-min-xxsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .ps-xxsmall {
    padding-left: calc(1 * (var(--distance-min-xxsmall) * 1px + (var(--distance-max-xxsmall) - var(--distance-min-xxsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ps-xxsmall {
    padding-left: calc(1 * var(--distance-max-xxsmall) * 1px);
  }
}

.p-xsmall {
  padding: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .p-xsmall {
    padding: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .p-xsmall {
    padding: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.py-xsmall {
  padding-top: calc(1 * var(--distance-min-xsmall) * 1px);
  padding-bottom: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .py-xsmall {
    padding-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-xsmall {
    padding-top: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .py-xsmall {
    padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-xsmall {
    padding-bottom: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.px-xsmall {
  padding-left: calc(1 * var(--distance-min-xsmall) * 1px);
  padding-right: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .px-xsmall {
    padding-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-xsmall {
    padding-left: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .px-xsmall {
    padding-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-xsmall {
    padding-right: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.pt-xsmall {
  padding-top: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .pt-xsmall {
    padding-top: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pt-xsmall {
    padding-top: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.pe-xsmall {
  padding-right: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .pe-xsmall {
    padding-right: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pe-xsmall {
    padding-right: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.pb-xsmall {
  padding-bottom: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .pb-xsmall {
    padding-bottom: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pb-xsmall {
    padding-bottom: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.ps-xsmall {
  padding-left: calc(1 * var(--distance-min-xsmall) * 1px);
}
@media screen and (min-width: 360px) {
  .ps-xsmall {
    padding-left: calc(1 * (var(--distance-min-xsmall) * 1px + (var(--distance-max-xsmall) - var(--distance-min-xsmall)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ps-xsmall {
    padding-left: calc(1 * var(--distance-max-xsmall) * 1px);
  }
}

.p-small {
  padding: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .p-small {
    padding: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .p-small {
    padding: calc(1 * var(--distance-max-small) * 1px);
  }
}

.py-small {
  padding-top: calc(1 * var(--distance-min-small) * 1px);
  padding-bottom: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .py-small {
    padding-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-small {
    padding-top: calc(1 * var(--distance-max-small) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .py-small {
    padding-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-small {
    padding-bottom: calc(1 * var(--distance-max-small) * 1px);
  }
}

.px-small {
  padding-left: calc(1 * var(--distance-min-small) * 1px);
  padding-right: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .px-small {
    padding-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-small {
    padding-left: calc(1 * var(--distance-max-small) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .px-small {
    padding-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-small {
    padding-right: calc(1 * var(--distance-max-small) * 1px);
  }
}

.pt-small {
  padding-top: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .pt-small {
    padding-top: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pt-small {
    padding-top: calc(1 * var(--distance-max-small) * 1px);
  }
}

.pe-small {
  padding-right: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .pe-small {
    padding-right: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pe-small {
    padding-right: calc(1 * var(--distance-max-small) * 1px);
  }
}

.pb-small {
  padding-bottom: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .pb-small {
    padding-bottom: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pb-small {
    padding-bottom: calc(1 * var(--distance-max-small) * 1px);
  }
}

.ps-small {
  padding-left: calc(1 * var(--distance-min-small) * 1px);
}
@media screen and (min-width: 360px) {
  .ps-small {
    padding-left: calc(1 * (var(--distance-min-small) * 1px + (var(--distance-max-small) - var(--distance-min-small)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ps-small {
    padding-left: calc(1 * var(--distance-max-small) * 1px);
  }
}

.p-medium {
  padding: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .p-medium {
    padding: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .p-medium {
    padding: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.py-medium {
  padding-top: calc(1 * var(--distance-min-medium) * 1px);
  padding-bottom: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .py-medium {
    padding-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-medium {
    padding-top: calc(1 * var(--distance-max-medium) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .py-medium {
    padding-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-medium {
    padding-bottom: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.px-medium {
  padding-left: calc(1 * var(--distance-min-medium) * 1px);
  padding-right: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .px-medium {
    padding-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-medium {
    padding-left: calc(1 * var(--distance-max-medium) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .px-medium {
    padding-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-medium {
    padding-right: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.pt-medium {
  padding-top: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .pt-medium {
    padding-top: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pt-medium {
    padding-top: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.pe-medium {
  padding-right: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .pe-medium {
    padding-right: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pe-medium {
    padding-right: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.pb-medium {
  padding-bottom: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .pb-medium {
    padding-bottom: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pb-medium {
    padding-bottom: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.ps-medium {
  padding-left: calc(1 * var(--distance-min-medium) * 1px);
}
@media screen and (min-width: 360px) {
  .ps-medium {
    padding-left: calc(1 * (var(--distance-min-medium) * 1px + (var(--distance-max-medium) - var(--distance-min-medium)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ps-medium {
    padding-left: calc(1 * var(--distance-max-medium) * 1px);
  }
}

.p-large {
  padding: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .p-large {
    padding: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .p-large {
    padding: calc(1 * var(--distance-max-large) * 1px);
  }
}

.py-large {
  padding-top: calc(1 * var(--distance-min-large) * 1px);
  padding-bottom: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .py-large {
    padding-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-large {
    padding-top: calc(1 * var(--distance-max-large) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .py-large {
    padding-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-large {
    padding-bottom: calc(1 * var(--distance-max-large) * 1px);
  }
}

.px-large {
  padding-left: calc(1 * var(--distance-min-large) * 1px);
  padding-right: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .px-large {
    padding-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-large {
    padding-left: calc(1 * var(--distance-max-large) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .px-large {
    padding-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-large {
    padding-right: calc(1 * var(--distance-max-large) * 1px);
  }
}

.pt-large {
  padding-top: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .pt-large {
    padding-top: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pt-large {
    padding-top: calc(1 * var(--distance-max-large) * 1px);
  }
}

.pe-large {
  padding-right: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .pe-large {
    padding-right: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pe-large {
    padding-right: calc(1 * var(--distance-max-large) * 1px);
  }
}

.pb-large {
  padding-bottom: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .pb-large {
    padding-bottom: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pb-large {
    padding-bottom: calc(1 * var(--distance-max-large) * 1px);
  }
}

.ps-large {
  padding-left: calc(1 * var(--distance-min-large) * 1px);
}
@media screen and (min-width: 360px) {
  .ps-large {
    padding-left: calc(1 * (var(--distance-min-large) * 1px + (var(--distance-max-large) - var(--distance-min-large)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ps-large {
    padding-left: calc(1 * var(--distance-max-large) * 1px);
  }
}

.p-xlarge {
  padding: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .p-xlarge {
    padding: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .p-xlarge {
    padding: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.py-xlarge {
  padding-top: calc(1 * var(--distance-min-xlarge) * 1px);
  padding-bottom: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .py-xlarge {
    padding-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-xlarge {
    padding-top: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .py-xlarge {
    padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .py-xlarge {
    padding-bottom: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.px-xlarge {
  padding-left: calc(1 * var(--distance-min-xlarge) * 1px);
  padding-right: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .px-xlarge {
    padding-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-xlarge {
    padding-left: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .px-xlarge {
    padding-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .px-xlarge {
    padding-right: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.pt-xlarge {
  padding-top: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .pt-xlarge {
    padding-top: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pt-xlarge {
    padding-top: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.pe-xlarge {
  padding-right: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .pe-xlarge {
    padding-right: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pe-xlarge {
    padding-right: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.pb-xlarge {
  padding-bottom: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .pb-xlarge {
    padding-bottom: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pb-xlarge {
    padding-bottom: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.ps-xlarge {
  padding-left: calc(1 * var(--distance-min-xlarge) * 1px);
}
@media screen and (min-width: 360px) {
  .ps-xlarge {
    padding-left: calc(1 * (var(--distance-min-xlarge) * 1px + (var(--distance-max-xlarge) - var(--distance-min-xlarge)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .ps-xlarge {
    padding-left: calc(1 * var(--distance-max-xlarge) * 1px);
  }
}

.m-gutters {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.m-min-gutters {
  margin-top: calc(var(--gutter-vertical-min) * 1px);
  margin-bottom: calc(var(--gutter-vertical-min) * 1px);
  margin-left: calc(var(--gutter-horizontal-min) * 1px);
  margin-right: calc(var(--gutter-horizontal-min) * 1px);
}

.mx-gutters {
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.mx-min-gutters {
  margin-left: calc(var(--gutter-horizontal-min) * 1px);
  margin-right: calc(var(--gutter-horizontal-min) * 1px);
}

.me-gutters {
  margin-right: var(--gutter-horizontal);
}

.ms-gutters {
  margin-left: var(--gutter-horizontal);
}

.my-gutters {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
}

.my-min-gutters {
  margin-top: calc(var(--gutter-vertical-min) * 1px);
  margin-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.mt-gutters {
  margin-top: var(--gutter-vertical);
}

.mb-gutters {
  margin-bottom: var(--gutter-vertical);
}

.my-gutters-horizontal {
  margin-top: var(--gutter-horizontal);
  margin-bottom: var(--gutter-horizontal);
}

.my-min-gutters-horizontal {
  margin-top: calc(var(--gutter-horizontal-min) * 1px);
  margin-bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.mt-gutter-horizontal {
  margin-top: var(--gutter-horizontal);
}

.mt-min-gutter-horizontal {
  margin-top: calc(var(--gutter-horizontal-min) * 1px);
}

.mb-gutter-horizontal {
  margin-bottom: var(--gutter-horizontal);
}

.mb-min-gutter-horizontal {
  margin-bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.mx-gutters-horizontal {
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.mx-min-gutters-horizontal {
  margin-left: calc(var(--gutter-horizontal-min) * 1px);
  margin-right: calc(var(--gutter-horizontal-min) * 1px);
}

.me-gutter-horizontal {
  margin-right: var(--gutter-horizontal);
}

.me-min-gutter-horizontal {
  margin-right: calc(var(--gutter-horizontal-min) * 1px);
}

.ms-gutter-horizontal {
  margin-left: var(--gutter-horizontal);
}

.ms-min-gutter-horizontal {
  margin-left: calc(var(--gutter-horizontal-min) * 1px);
}

.my-gutters-vertical {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
}

.my-min-gutters-vertical {
  margin-top: calc(var(--gutter-vertical-min) * 1px);
  margin-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.mt-gutter-vertical {
  margin-top: var(--gutter-vertical);
}

.mt-min-gutter-vertical {
  margin-top: calc(var(--gutter-vertical-min) * 1px);
}

.mb-gutter-vertical {
  margin-bottom: var(--gutter-vertical);
}

.mb-min-gutter-vertical {
  margin-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.mx-gutters-vertical {
  margin-left: var(--gutter-vertical);
  margin-right: var(--gutter-vertical);
}

.mx-min-gutters-vertical {
  margin-left: calc(var(--gutter-vertical-min) * 1px);
  margin-right: calc(var(--gutter-vertical-min) * 1px);
}

.me-gutter-vertical {
  margin-right: var(--gutter-vertical);
}

.me-min-gutter-vertical {
  margin-right: calc(var(--gutter-vertical-min) * 1px);
}

.ms-gutter-vertical {
  margin-left: var(--gutter-vertical);
}

.ms-min-gutter-vertical {
  margin-left: calc(var(--gutter-vertical-min) * 1px);
}

.p-gutters {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.p-min-gutters {
  padding-top: calc(var(--gutter-vertical-min) * 1px);
  padding-bottom: calc(var(--gutter-vertical-min) * 1px);
  padding-left: calc(var(--gutter-horizontal-min) * 1px);
  padding-right: calc(var(--gutter-horizontal-min) * 1px);
}

.px-gutters {
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.px-min-gutters {
  padding-left: calc(var(--gutter-horizontal-min) * 1px);
  padding-right: calc(var(--gutter-horizontal-min) * 1px);
}

.pe-gutters {
  padding-right: var(--gutter-horizontal);
}

.ps-gutters {
  padding-left: var(--gutter-horizontal);
}

.py-gutters {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
}

.py-min-gutters {
  padding-top: calc(var(--gutter-vertical-min) * 1px);
  padding-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.pt-gutters {
  padding-top: var(--gutter-vertical);
}

.pb-gutters {
  padding-bottom: var(--gutter-vertical);
}

.py-gutters-horizontal {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.py-min-gutters-horizontal {
  padding-top: calc(var(--gutter-horizontal-min) * 1px);
  padding-bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.pt-gutter-horizontal {
  padding-top: var(--gutter-horizontal);
}

.pt-min-gutter-horizontal {
  padding-top: calc(var(--gutter-horizontal-min) * 1px);
}

.pb-gutter-horizontal {
  padding-bottom: var(--gutter-horizontal);
}

.pb-min-gutter-horizontal {
  padding-bottom: calc(var(--gutter-horizontal-min) * 1px);
}

.px-gutters-horizontal {
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.px-min-gutters-horizontal {
  padding-left: calc(var(--gutter-horizontal-min) * 1px);
  padding-right: calc(var(--gutter-horizontal-min) * 1px);
}

.pe-gutter-horizontal {
  padding-right: var(--gutter-horizontal);
}

.pe-min-gutter-horizontal {
  padding-right: calc(var(--gutter-horizontal-min) * 1px);
}

.ps-gutter-horizontal {
  padding-left: var(--gutter-horizontal);
}

.ps-min-gutter-horizontal {
  padding-left: calc(var(--gutter-horizontal-min) * 1px);
}

.py-gutters-vertical {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
}

.py-min-gutters-vertical {
  padding-top: calc(var(--gutter-vertical-min) * 1px);
  padding-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.pt-gutter-vertical {
  padding-top: var(--gutter-vertical);
}

.pt-min-gutter-vertical {
  padding-top: calc(var(--gutter-vertical-min) * 1px);
}

.pb-gutter-vertical {
  padding-bottom: var(--gutter-vertical);
}

.pb-min-gutter-vertical {
  padding-bottom: calc(var(--gutter-vertical-min) * 1px);
}

.px-gutters-vertical {
  padding-left: var(--gutter-vertical);
  padding-right: var(--gutter-vertical);
}

.px-min-gutters-vertical {
  padding-left: calc(var(--gutter-vertical-min) * 1px);
  padding-right: calc(var(--gutter-vertical-min) * 1px);
}

.pe-gutter-vertical {
  padding-right: var(--gutter-vertical);
}

.pe-min-gutter-vertical {
  padding-right: calc(var(--gutter-vertical-min) * 1px);
}

.ps-gutter-vertical {
  padding-left: var(--gutter-vertical);
}

.ps-min-gutter-vertical {
  padding-left: calc(var(--gutter-vertical-min) * 1px);
}

.py-half-gutters {
  padding-top: max(var(--gutter-vertical) / 2, var(--gutter-vertical-min) * 1px);
  padding-bottom: max(var(--gutter-vertical) / 2, var(--gutter-vertical-min) * 1px);
}

.px-half-gutters {
  padding-left: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
  padding-right: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
}

.ps-half-gutters {
  padding-left: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
}

.pe-half-gutters {
  padding-right: max(var(--gutter-horizontal) / 2, var(--gutter-horizontal-min) * 1px);
}

.p-gutters-horizontal {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.px-gutters-horizontal {
  padding-left: var(--gutter-horizontal);
  padding-right: var(--gutter-horizontal);
}

.ps-gutter-horizontal {
  padding-left: var(--gutter-horizontal);
}

.pe-gutter-horizontal {
  padding-right: var(--gutter-horizontal);
}

.py-gutters-horizontal {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.pt-gutter-horizontal {
  padding-top: var(--gutter-horizontal);
}

.pb-gutter-horizontal {
  padding-bottom: var(--gutter-horizontal);
}

.m-gutters-horizontal {
  margin-top: var(--gutter-horizontal);
  margin-bottom: var(--gutter-horizontal);
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.mx-gutters-horizontal {
  margin-left: var(--gutter-horizontal);
  margin-right: var(--gutter-horizontal);
}

.ms-gutter-horizontal {
  margin-left: var(--gutter-horizontal);
}

.me-gutter-horizontal {
  margin-right: var(--gutter-horizontal);
}

.my-gutters-horizontal {
  margin-top: var(--gutter-horizontal);
  margin-bottom: var(--gutter-horizontal);
}

.mt-gutter-horizontal {
  margin-top: var(--gutter-horizontal);
}

.mb-gutter-horizontal {
  margin-bottom: var(--gutter-horizontal);
}

.p-gutters-vertical {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
  padding-left: var(--gutter-vertical);
  padding-right: var(--gutter-vertical);
}

.px-gutters-vertical {
  padding-left: var(--gutter-vertical);
  padding-right: var(--gutter-vertical);
}

.ps-gutter-vertical {
  padding-left: var(--gutter-vertical);
}

.pe-gutter-vertical {
  padding-right: var(--gutter-vertical);
}

.py-gutters-vertical {
  padding-top: var(--gutter-vertical);
  padding-bottom: var(--gutter-vertical);
}

.pt-gutter-vertical {
  padding-top: var(--gutter-vertical);
}

.pb-gutter-vertical {
  padding-bottom: var(--gutter-vertical);
}

.m-gutters-vertical {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
  margin-left: var(--gutter-vertical);
  margin-right: var(--gutter-vertical);
}

.mx-gutters-vertical {
  margin-left: var(--gutter-vertical);
  margin-right: var(--gutter-vertical);
}

.ms-gutter-vertical {
  margin-left: var(--gutter-vertical);
}

.me-gutter-vertical {
  margin-right: var(--gutter-vertical);
}

.my-gutters-vertical {
  margin-top: var(--gutter-vertical);
  margin-bottom: var(--gutter-vertical);
}

.mt-gutter-vertical {
  margin-top: var(--gutter-vertical);
}

.mb-gutter-vertical {
  margin-bottom: var(--gutter-vertical);
}

.js-arts-split-text__wrapper-line {
  display: block;
  overflow: hidden;
}

.js-arts-split-text__wrapper-char {
  display: inline-flex;
  overflow: hidden;
}

.js-arts-split-text__wrapper-word {
  display: inline-flex;
  overflow: hidden;
}

[data-arts-color-theme=dark] {
  color: var(--paragraph-color-dark-theme);
  --paragraph-color: var(--paragraph-color-dark-theme);
  --ui-element-color: var(--ui-element-color-dark-theme);
  --display-xxl-color: var(--display-xxl-color-dark-theme);
  --display-xl-color: var(--display-xl-color-dark-theme);
  --display-caps-color: var(--display-caps-color-dark-theme);
  --h1-color: var(--h1-color-dark-theme);
  --h2-color: var(--h2-color-dark-theme);
  --h3-color: var(--h3-color-dark-theme);
  --h4-color: var(--h4-color-dark-theme);
  --h5-color: var(--h5-color-dark-theme);
  --h6-color: var(--h6-color-dark-theme);
  --blockquote-color: var(--blockquote-color-dark-theme);
  --dropcap-color: var(--dropcap-color-dark-theme);
  --subheading-color: var(--subheading-color-dark-theme);
  --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
  --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
  --super-label-color: var(--super-label-color-dark-theme);
  --color-accent: var(--color-accent-dark-theme);
  --color-border-solid: var(--color-border-solid-dark-theme);
  --color-border-opacity: var(--color-border-opacity-dark-theme);
  --color-links: var(--color-links-dark-theme);
}

@media screen and (max-width: 1024px) {
  [data-arts-color-theme-lg=dark] {
    color: var(--paragraph-color-dark-theme);
    --paragraph-color: var(--paragraph-color-dark-theme);
    --ui-element-color: var(--ui-element-color-dark-theme);
    --display-xxl-color: var(--display-xxl-color-dark-theme);
    --display-xl-color: var(--display-xl-color-dark-theme);
    --display-caps-color: var(--display-caps-color-dark-theme);
    --h1-color: var(--h1-color-dark-theme);
    --h2-color: var(--h2-color-dark-theme);
    --h3-color: var(--h3-color-dark-theme);
    --h4-color: var(--h4-color-dark-theme);
    --h5-color: var(--h5-color-dark-theme);
    --h6-color: var(--h6-color-dark-theme);
    --blockquote-color: var(--blockquote-color-dark-theme);
    --dropcap-color: var(--dropcap-color-dark-theme);
    --subheading-color: var(--subheading-color-dark-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
    --super-label-color: var(--super-label-color-dark-theme);
    --color-accent: var(--color-accent-dark-theme);
    --color-border-solid: var(--color-border-solid-dark-theme);
    --color-border-opacity: var(--color-border-opacity-dark-theme);
    --color-links: var(--color-links-dark-theme);
  }
}
@media screen and (max-width: 991px) {
  [data-arts-color-theme-md=dark] {
    color: var(--paragraph-color-dark-theme);
    --paragraph-color: var(--paragraph-color-dark-theme);
    --ui-element-color: var(--ui-element-color-dark-theme);
    --display-xxl-color: var(--display-xxl-color-dark-theme);
    --display-xl-color: var(--display-xl-color-dark-theme);
    --display-caps-color: var(--display-caps-color-dark-theme);
    --h1-color: var(--h1-color-dark-theme);
    --h2-color: var(--h2-color-dark-theme);
    --h3-color: var(--h3-color-dark-theme);
    --h4-color: var(--h4-color-dark-theme);
    --h5-color: var(--h5-color-dark-theme);
    --h6-color: var(--h6-color-dark-theme);
    --blockquote-color: var(--blockquote-color-dark-theme);
    --dropcap-color: var(--dropcap-color-dark-theme);
    --subheading-color: var(--subheading-color-dark-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
    --super-label-color: var(--super-label-color-dark-theme);
    --color-accent: var(--color-accent-dark-theme);
    --color-border-solid: var(--color-border-solid-dark-theme);
    --color-border-opacity: var(--color-border-opacity-dark-theme);
    --color-links: var(--color-links-dark-theme);
  }
}
@media screen and (max-width: 767px) {
  [data-arts-color-theme-sm=dark] {
    color: var(--paragraph-color-dark-theme);
    --paragraph-color: var(--paragraph-color-dark-theme);
    --ui-element-color: var(--ui-element-color-dark-theme);
    --display-xxl-color: var(--display-xxl-color-dark-theme);
    --display-xl-color: var(--display-xl-color-dark-theme);
    --display-caps-color: var(--display-caps-color-dark-theme);
    --h1-color: var(--h1-color-dark-theme);
    --h2-color: var(--h2-color-dark-theme);
    --h3-color: var(--h3-color-dark-theme);
    --h4-color: var(--h4-color-dark-theme);
    --h5-color: var(--h5-color-dark-theme);
    --h6-color: var(--h6-color-dark-theme);
    --blockquote-color: var(--blockquote-color-dark-theme);
    --dropcap-color: var(--dropcap-color-dark-theme);
    --subheading-color: var(--subheading-color-dark-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
    --super-label-color: var(--super-label-color-dark-theme);
    --color-accent: var(--color-accent-dark-theme);
    --color-border-solid: var(--color-border-solid-dark-theme);
    --color-border-opacity: var(--color-border-opacity-dark-theme);
    --color-links: var(--color-links-dark-theme);
  }
}
@media screen and (max-width: 576px) {
  [data-arts-color-theme-xs=dark] {
    color: var(--paragraph-color-dark-theme);
    --paragraph-color: var(--paragraph-color-dark-theme);
    --ui-element-color: var(--ui-element-color-dark-theme);
    --display-xxl-color: var(--display-xxl-color-dark-theme);
    --display-xl-color: var(--display-xl-color-dark-theme);
    --display-caps-color: var(--display-caps-color-dark-theme);
    --h1-color: var(--h1-color-dark-theme);
    --h2-color: var(--h2-color-dark-theme);
    --h3-color: var(--h3-color-dark-theme);
    --h4-color: var(--h4-color-dark-theme);
    --h5-color: var(--h5-color-dark-theme);
    --h6-color: var(--h6-color-dark-theme);
    --blockquote-color: var(--blockquote-color-dark-theme);
    --dropcap-color: var(--dropcap-color-dark-theme);
    --subheading-color: var(--subheading-color-dark-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-dark-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-dark-theme);
    --super-label-color: var(--super-label-color-dark-theme);
    --color-accent: var(--color-accent-dark-theme);
    --color-border-solid: var(--color-border-solid-dark-theme);
    --color-border-opacity: var(--color-border-opacity-dark-theme);
    --color-links: var(--color-links-dark-theme);
  }
}
[data-arts-color-theme=light] {
  color: var(--paragraph-color-light-theme);
  --paragraph-color: var(--paragraph-color-light-theme);
  --ui-element-color: var(--ui-element-color-light-theme);
  --display-xxl-color: var(--display-xxl-color-light-theme);
  --display-xl-color: var(--display-xl-color-light-theme);
  --display-caps-color: var(--display-caps-color-light-theme);
  --h1-color: var(--h1-color-light-theme);
  --h2-color: var(--h2-color-light-theme);
  --h3-color: var(--h3-color-light-theme);
  --h4-color: var(--h4-color-light-theme);
  --h5-color: var(--h5-color-light-theme);
  --h6-color: var(--h6-color-light-theme);
  --blockquote-color: var(--blockquote-color-light-theme);
  --dropcap-color: var(--dropcap-color-light-theme);
  --subheading-color: var(--subheading-color-light-theme);
  --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
  --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
  --super-label-color: var(--super-label-color-light-theme);
  --color-accent: var(--color-accent-light-theme);
  --color-border-solid: var(--color-border-solid-light-theme);
  --color-border-opacity: var(--color-border-opacity-light-theme);
  --color-links: var(--color-links-light-theme);
}

@media screen and (max-width: 1024px) {
  [data-arts-color-theme-lg=light] {
    color: var(--paragraph-color-light-theme);
    --paragraph-color: var(--paragraph-color-light-theme);
    --ui-element-color: var(--ui-element-color-light-theme);
    --display-xxl-color: var(--display-xxl-color-light-theme);
    --display-xl-color: var(--display-xl-color-light-theme);
    --display-caps-color: var(--display-caps-color-light-theme);
    --h1-color: var(--h1-color-light-theme);
    --h2-color: var(--h2-color-light-theme);
    --h3-color: var(--h3-color-light-theme);
    --h4-color: var(--h4-color-light-theme);
    --h5-color: var(--h5-color-light-theme);
    --h6-color: var(--h6-color-light-theme);
    --blockquote-color: var(--blockquote-color-light-theme);
    --dropcap-color: var(--dropcap-color-light-theme);
    --subheading-color: var(--subheading-color-light-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
    --super-label-color: var(--super-label-color-light-theme);
    --color-accent: var(--color-accent-light-theme);
    --color-border-solid: var(--color-border-solid-light-theme);
    --color-border-opacity: var(--color-border-opacity-light-theme);
    --color-links: var(--color-links-light-theme);
  }
}
@media screen and (max-width: 991px) {
  [data-arts-color-theme-md=light] {
    color: var(--paragraph-color-light-theme);
    --paragraph-color: var(--paragraph-color-light-theme);
    --ui-element-color: var(--ui-element-color-light-theme);
    --display-xxl-color: var(--display-xxl-color-light-theme);
    --display-xl-color: var(--display-xl-color-light-theme);
    --display-caps-color: var(--display-caps-color-light-theme);
    --h1-color: var(--h1-color-light-theme);
    --h2-color: var(--h2-color-light-theme);
    --h3-color: var(--h3-color-light-theme);
    --h4-color: var(--h4-color-light-theme);
    --h5-color: var(--h5-color-light-theme);
    --h6-color: var(--h6-color-light-theme);
    --blockquote-color: var(--blockquote-color-light-theme);
    --dropcap-color: var(--dropcap-color-light-theme);
    --subheading-color: var(--subheading-color-light-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
    --super-label-color: var(--super-label-color-light-theme);
    --color-accent: var(--color-accent-light-theme);
    --color-border-solid: var(--color-border-solid-light-theme);
    --color-border-opacity: var(--color-border-opacity-light-theme);
    --color-links: var(--color-links-light-theme);
  }
}
@media screen and (max-width: 767px) {
  [data-arts-color-theme-sm=light] {
    color: var(--paragraph-color-light-theme);
    --paragraph-color: var(--paragraph-color-light-theme);
    --ui-element-color: var(--ui-element-color-light-theme);
    --display-xxl-color: var(--display-xxl-color-light-theme);
    --display-xl-color: var(--display-xl-color-light-theme);
    --display-caps-color: var(--display-caps-color-light-theme);
    --h1-color: var(--h1-color-light-theme);
    --h2-color: var(--h2-color-light-theme);
    --h3-color: var(--h3-color-light-theme);
    --h4-color: var(--h4-color-light-theme);
    --h5-color: var(--h5-color-light-theme);
    --h6-color: var(--h6-color-light-theme);
    --blockquote-color: var(--blockquote-color-light-theme);
    --dropcap-color: var(--dropcap-color-light-theme);
    --subheading-color: var(--subheading-color-light-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
    --super-label-color: var(--super-label-color-light-theme);
    --color-accent: var(--color-accent-light-theme);
    --color-border-solid: var(--color-border-solid-light-theme);
    --color-border-opacity: var(--color-border-opacity-light-theme);
    --color-links: var(--color-links-light-theme);
  }
}
@media screen and (max-width: 576px) {
  [data-arts-color-theme-xs=light] {
    color: var(--paragraph-color-light-theme);
    --paragraph-color: var(--paragraph-color-light-theme);
    --ui-element-color: var(--ui-element-color-light-theme);
    --display-xxl-color: var(--display-xxl-color-light-theme);
    --display-xl-color: var(--display-xl-color-light-theme);
    --display-caps-color: var(--display-caps-color-light-theme);
    --h1-color: var(--h1-color-light-theme);
    --h2-color: var(--h2-color-light-theme);
    --h3-color: var(--h3-color-light-theme);
    --h4-color: var(--h4-color-light-theme);
    --h5-color: var(--h5-color-light-theme);
    --h6-color: var(--h6-color-light-theme);
    --blockquote-color: var(--blockquote-color-light-theme);
    --dropcap-color: var(--dropcap-color-light-theme);
    --subheading-color: var(--subheading-color-light-theme);
    --overlay-menu-item-color: var(--overlay-menu-item-color-light-theme);
    --overlay-submenu-item-color: var(--overlay-submenu-item-color-light-theme);
    --super-label-color: var(--super-label-color-light-theme);
    --color-accent: var(--color-accent-light-theme);
    --color-border-solid: var(--color-border-solid-light-theme);
    --color-border-opacity: var(--color-border-opacity-light-theme);
    --color-links: var(--color-links-light-theme);
  }
}
.transition-opacity {
  transition: opacity 0.3s ease;
}

.transition-color {
  transition: color 0.3s ease;
}

.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
  float: left;
  font-style: normal;
  margin: 0.15em 0.25em 0 0;
}
.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter:after {
  content: "";
  display: table;
  clear: both;
}
.has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter:after {
  content: "";
  display: table;
  clear: both;
}

.arts-split-text:not(.js-arts-split-text) .has-drop-cap > div:first-child {
  display: inline-block !important;
  float: left;
}
.arts-split-text:not(.js-arts-split-text) .has-drop-cap > div:first-child:after {
  content: "";
  display: table;
  clear: both;
}
.arts-split-text:not(.js-arts-split-text) .has-drop-cap:first-letter {
  margin: unset;
  float: unset;
  font-size: unset;
  text-transform: unset;
}

.display-xxl {
  font-family: var(--display-xxl-font-family);
  font-weight: var(--display-xxl-font-weight);
  line-height: var(--display-xxl-line-height);
  letter-spacing: var(--display-xxl-letter-spacing);
  font-size: calc(var(--display-xxl-min-font-size) * 1px);
  color: var(--display-xxl-color);
}
@media screen and (min-width: 360px) {
  .display-xxl {
    font-size: calc(var(--display-xxl-min-font-size) * 1px + (var(--display-xxl-max-font-size) - var(--display-xxl-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .display-xxl {
    font-size: calc(var(--display-xxl-max-font-size) * 1px);
  }
}

.display-xl {
  font-family: var(--display-xl-font-family);
  font-weight: var(--display-xl-font-weight);
  line-height: var(--display-xl-line-height);
  letter-spacing: var(--display-xl-letter-spacing);
  font-size: calc(var(--display-xl-min-font-size) * 1px);
  color: var(--display-xl-color);
}
@media screen and (min-width: 360px) {
  .display-xl {
    font-size: calc(var(--display-xl-min-font-size) * 1px + (var(--display-xl-max-font-size) - var(--display-xl-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .display-xl {
    font-size: calc(var(--display-xl-max-font-size) * 1px);
  }
}

.display-caps {
  font-family: var(--display-caps-font-family);
  font-weight: var(--display-caps-font-weight);
  line-height: var(--display-caps-line-height);
  letter-spacing: var(--display-caps-letter-spacing);
  font-size: calc(var(--display-caps-min-font-size) * 1px);
  color: var(--display-caps-color);
}
@media screen and (min-width: 360px) {
  .display-caps {
    font-size: calc(var(--display-caps-min-font-size) * 1px + (var(--display-caps-max-font-size) - var(--display-caps-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .display-caps {
    font-size: calc(var(--display-caps-max-font-size) * 1px);
  }
}

.display-caps {
  text-transform: uppercase;
}

h1, .h1 {
  font-family: var(--h1-font-family);
  font-weight: var(--h1-font-weight);
  line-height: var(--h1-line-height);
  letter-spacing: var(--h1-letter-spacing);
  font-size: calc(var(--h1-min-font-size) * 1px);
  color: var(--h1-color);
}
@media screen and (min-width: 360px) {
  h1, .h1 {
    font-size: calc(var(--h1-min-font-size) * 1px + (var(--h1-max-font-size) - var(--h1-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  h1, .h1 {
    font-size: calc(var(--h1-max-font-size) * 1px);
  }
}

h2, .h2 {
  font-family: var(--h2-font-family);
  font-weight: var(--h2-font-weight);
  line-height: var(--h2-line-height);
  letter-spacing: var(--h2-letter-spacing);
  font-size: calc(var(--h2-min-font-size) * 1px);
  color: var(--h2-color);
}
@media screen and (min-width: 360px) {
  h2, .h2 {
    font-size: calc(var(--h2-min-font-size) * 1px + (var(--h2-max-font-size) - var(--h2-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  h2, .h2 {
    font-size: calc(var(--h2-max-font-size) * 1px);
  }
}

h3, .h3 {
  font-family: var(--h3-font-family);
  font-weight: var(--h3-font-weight);
  line-height: var(--h3-line-height);
  letter-spacing: var(--h3-letter-spacing);
  font-size: calc(var(--h3-min-font-size) * 1px);
  color: var(--h3-color);
}
@media screen and (min-width: 360px) {
  h3, .h3 {
    font-size: calc(var(--h3-min-font-size) * 1px + (var(--h3-max-font-size) - var(--h3-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  h3, .h3 {
    font-size: calc(var(--h3-max-font-size) * 1px);
  }
}

h4, .h4, .comments-title, .comment-reply-title {
  font-family: var(--h4-font-family);
  font-weight: var(--h4-font-weight);
  line-height: var(--h4-line-height);
  letter-spacing: var(--h4-letter-spacing);
  font-size: calc(var(--h4-min-font-size) * 1px);
  color: var(--h4-color);
}
@media screen and (min-width: 360px) {
  h4, .h4, .comments-title, .comment-reply-title {
    font-size: calc(var(--h4-min-font-size) * 1px + (var(--h4-max-font-size) - var(--h4-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  h4, .h4, .comments-title, .comment-reply-title {
    font-size: calc(var(--h4-max-font-size) * 1px);
  }
}

h5, .h5 {
  font-family: var(--h5-font-family);
  font-weight: var(--h5-font-weight);
  line-height: var(--h5-line-height);
  letter-spacing: var(--h5-letter-spacing);
  font-size: calc(var(--h5-min-font-size) * 1px);
  color: var(--h5-color);
}
@media screen and (min-width: 360px) {
  h5, .h5 {
    font-size: calc(var(--h5-min-font-size) * 1px + (var(--h5-max-font-size) - var(--h5-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  h5, .h5 {
    font-size: calc(var(--h5-max-font-size) * 1px);
  }
}

h6, .h6, .comment-body .fn {
  font-family: var(--h6-font-family);
  font-weight: var(--h6-font-weight);
  line-height: var(--h6-line-height);
  letter-spacing: var(--h6-letter-spacing);
  font-size: calc(var(--h6-min-font-size) * 1px);
  color: var(--h6-color);
}
@media screen and (min-width: 360px) {
  h6, .h6, .comment-body .fn {
    font-size: calc(var(--h6-min-font-size) * 1px + (var(--h6-max-font-size) - var(--h6-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  h6, .h6, .comment-body .fn {
    font-size: calc(var(--h6-max-font-size) * 1px);
  }
}

.subheading, .comment-edit-link, .comment-reply-link, .comment-metadata {
  font-family: var(--subheading-font-family);
  font-weight: var(--subheading-font-weight);
  line-height: var(--subheading-line-height);
  letter-spacing: var(--subheading-letter-spacing);
  font-size: calc(var(--subheading-min-font-size) * 1px);
  color: var(--subheading-color);
}
@media screen and (min-width: 360px) {
  .subheading, .comment-edit-link, .comment-reply-link, .comment-metadata {
    font-size: calc(var(--subheading-min-font-size) * 1px + (var(--subheading-max-font-size) - var(--subheading-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .subheading, .comment-edit-link, .comment-reply-link, .comment-metadata {
    font-size: calc(var(--subheading-max-font-size) * 1px);
  }
}

.subheading, .comment-edit-link, .comment-reply-link, .comment-metadata {
  text-transform: uppercase;
}

.overlay-menu-item {
  font-family: var(--overlay-menu-item-font-family);
  font-weight: var(--overlay-menu-item-font-weight);
  line-height: var(--overlay-menu-item-line-height);
  letter-spacing: var(--overlay-menu-item-letter-spacing);
  font-size: calc(var(--overlay-menu-item-min-font-size) * 1px);
  color: var(--overlay-menu-item-color);
}
@media screen and (min-width: 360px) {
  .overlay-menu-item {
    font-size: calc(var(--overlay-menu-item-min-font-size) * 1px + (var(--overlay-menu-item-max-font-size) - var(--overlay-menu-item-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .overlay-menu-item {
    font-size: calc(var(--overlay-menu-item-max-font-size) * 1px);
  }
}

.overlay-submenu-item {
  font-family: var(--overlay-submenu-item-font-family);
  font-weight: var(--overlay-submenu-item-font-weight);
  line-height: var(--overlay-submenu-item-line-height);
  letter-spacing: var(--overlay-submenu-item-letter-spacing);
  font-size: calc(var(--overlay-submenu-item-min-font-size) * 1px);
  color: var(--overlay-submenu-item-color);
}
@media screen and (min-width: 360px) {
  .overlay-submenu-item {
    font-size: calc(var(--overlay-submenu-item-min-font-size) * 1px + (var(--overlay-submenu-item-max-font-size) - var(--overlay-submenu-item-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .overlay-submenu-item {
    font-size: calc(var(--overlay-submenu-item-max-font-size) * 1px);
  }
}

.paragraph {
  font-family: var(--paragraph-font-family);
  font-weight: var(--paragraph-font-weight);
  line-height: var(--paragraph-line-height);
  letter-spacing: var(--paragraph-letter-spacing);
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  color: var(--paragraph-color);
}
@media screen and (min-width: 360px) {
  .paragraph {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .paragraph {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}

.ui-element, .header__col {
  font-family: var(--ui-element-font-family);
  font-weight: var(--ui-element-font-weight);
  line-height: var(--ui-element-line-height);
  letter-spacing: var(--ui-element-letter-spacing);
  font-size: calc(var(--ui-element-min-font-size) * 1px);
  color: var(--ui-element-color);
}
@media screen and (min-width: 360px) {
  .ui-element, .header__col {
    font-size: calc(var(--ui-element-min-font-size) * 1px + (var(--ui-element-max-font-size) - var(--ui-element-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .ui-element, .header__col {
    font-size: calc(var(--ui-element-max-font-size) * 1px);
  }
}

.dropcap, .has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
  font-family: var(--dropcap-font-family);
  font-weight: var(--dropcap-font-weight);
  line-height: var(--dropcap-line-height);
  letter-spacing: var(--dropcap-letter-spacing);
  font-size: calc(var(--dropcap-min-font-size) * 1px);
  color: var(--dropcap-color);
}
@media screen and (min-width: 360px) {
  .dropcap, .has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
    font-size: calc(var(--dropcap-min-font-size) * 1px + (var(--dropcap-max-font-size) - var(--dropcap-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .dropcap, .has-drop-cap:not(:focus):not(.has-drop-cap_split):first-letter {
    font-size: calc(var(--dropcap-max-font-size) * 1px);
  }
}

.super-label {
  font-family: var(--super-label-font-family);
  font-weight: var(--super-label-font-weight);
  line-height: var(--super-label-line-height);
  letter-spacing: var(--super-label-letter-spacing);
  font-size: calc(var(--super-label-min-font-size) * 1px);
  color: var(--super-label-color);
}
@media screen and (min-width: 360px) {
  .super-label {
    font-size: calc(var(--super-label-min-font-size) * 1px + (var(--super-label-max-font-size) - var(--super-label-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .super-label {
    font-size: calc(var(--super-label-max-font-size) * 1px);
  }
}

a {
  transition: color 0.3s ease;
  text-decoration: none;
  color: var(--color-links);
}
a:hover {
  color: var(--color-accent);
  text-decoration: none;
}
a * {
  pointer-events: none;
}

.strong {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.normal {
  font-style: normal;
}

.xxl {
  font-size: calc(var(--display-xxl-min-font-size) * 1px);
  font-weight: var(--display-xxl-font-weight);
  letter-spacing: var(--display-xxl-letter-spacing);
  line-height: 1.15;
}
@media screen and (min-width: 360px) {
  .xxl {
    font-size: calc(var(--display-xxl-min-font-size) * 1px + (var(--display-xxl-max-font-size) - var(--display-xxl-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .xxl {
    font-size: calc(var(--display-xxl-max-font-size) * 1px);
  }
}

.xl {
  font-size: calc(var(--display-xl-min-font-size) * 1px);
  font-weight: var(--display-xl-font-weight);
  letter-spacing: var(--display-xl-letter-spacing);
}
@media screen and (min-width: 360px) {
  .xl {
    font-size: calc(var(--display-xl-min-font-size) * 1px + (var(--display-xl-max-font-size) - var(--display-xl-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  .xl {
    font-size: calc(var(--display-xl-max-font-size) * 1px);
  }
}

.xl-fix {
  font-size: calc(1 * 36 * 1px);
}
@media screen and (min-width: 360px) {
  .xl-fix {
    font-size: calc(1 * (36 * 1px + (160 - 36) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .xl-fix {
    font-size: calc(1 * 160 * 1px);
  }
}

.xl-other {
  font-size: calc(1 * 50 * 1px);
  font-family: var(--font-primary);
  font-weight: 600;
}
@media screen and (min-width: 360px) {
  .xl-other {
    font-size: calc(1 * (50 * 1px + (150 - 50) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .xl-other {
    font-size: calc(1 * 150 * 1px);
  }
}

.h2-fix {
  font-size: calc(1 * 36 * 1px);
}
@media screen and (min-width: 360px) {
  .h2-fix {
    font-size: calc(1 * (36 * 1px + (48 - 36) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .h2-fix {
    font-size: calc(1 * 48 * 1px);
  }
}

h1, h2, h3 {
  margin-top: calc(1 * 16 * 1px);
  margin-bottom: calc(1 * 16 * 1px);
}
@media screen and (min-width: 360px) {
  h1, h2, h3 {
    margin-top: calc(1 * (16 * 1px + (24 - 16) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h1, h2, h3 {
    margin-top: calc(1 * 24 * 1px);
  }
}
@media screen and (min-width: 360px) {
  h1, h2, h3 {
    margin-bottom: calc(1 * (16 * 1px + (24 - 16) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h1, h2, h3 {
    margin-bottom: calc(1 * 24 * 1px);
  }
}

h4, h5, h6 {
  margin-top: calc(1 * 16 * 1px);
  margin-bottom: calc(1 * 16 * 1px);
}
@media screen and (min-width: 360px) {
  h4, h5, h6 {
    margin-top: calc(1 * (16 * 1px + (24 - 16) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h4, h5, h6 {
    margin-top: calc(1 * 24 * 1px);
  }
}
@media screen and (min-width: 360px) {
  h4, h5, h6 {
    margin-bottom: calc(1 * (16 * 1px + (24 - 16) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  h4, h5, h6 {
    margin-bottom: calc(1 * 24 * 1px);
  }
}

h6, .h6, .comment-body .fn {
  text-transform: uppercase;
}

p {
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  line-height: 1.5;
}
@media screen and (min-width: 360px) {
  p {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  p {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}

blockquote {
  display: block;
  font-size: calc(var(--blockquote-min-font-size) * 1px);
  line-height: var(--blockquote-line-height);
  letter-spacing: calc(var(--blockquote-letter-spacing) * 1px);
  font-style: italic;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding: 1.5em;
  border: 1px solid var(--color-border-opacity);
  border-radius: 4px;
}
@media screen and (min-width: 360px) {
  blockquote {
    font-size: calc(var(--blockquote-min-font-size) * 1px + (var(--blockquote-max-font-size) - var(--blockquote-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  blockquote {
    font-size: calc(var(--blockquote-max-font-size) * 1px);
  }
}
blockquote p {
  margin-top: 0;
  font-size: calc(var(--blockquote-min-font-size) * 1px);
  line-height: var(--blockquote-line-height);
}
@media screen and (min-width: 360px) {
  blockquote p {
    font-size: calc(var(--blockquote-min-font-size) * 1px + (var(--blockquote-max-font-size) - var(--blockquote-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  blockquote p {
    font-size: calc(var(--blockquote-max-font-size) * 1px);
  }
}
blockquote p:last-child, blockquote p:last-of-type {
  margin-bottom: 0;
}
blockquote cite {
  display: block;
  margin-top: 1em;
  font-size: calc(var(--paragraph-min-font-size) * 1px);
  line-height: 1;
  color: var(--paragraph-color);
  font-style: normal;
  font-weight: bold;
}
@media screen and (min-width: 360px) {
  blockquote cite {
    font-size: calc(var(--paragraph-min-font-size) * 1px + (var(--paragraph-max-font-size) - var(--paragraph-min-font-size)) * (100vw - 360px) / 1560);
  }
}
@media screen and (min-width: 1920px) {
  blockquote cite {
    font-size: calc(var(--paragraph-max-font-size) * 1px);
  }
}
blockquote svg {
  max-width: calc(1 * 30 * 1px);
  height: auto;
  margin-top: 4px;
  fill: currentColor;
}
@media screen and (min-width: 360px) {
  blockquote svg {
    max-width: calc(1 * (30 * 1px + (50 - 30) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  blockquote svg {
    max-width: calc(1 * 50 * 1px);
  }
}

.font-primary {
  font-family: var(--font-primary);
}

.font-tertiary {
  font-family: var(--font-tertiary);
}

.underline, .underline-hover-self {
  display: inline-block;
  position: relative;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
}
.underline .arts-split-text__line, .underline-hover-self .arts-split-text__line {
  display: inline-block !important;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.4s ease;
}

.underline-hover-self {
  background-position: 100% 100%;
  background-size: 0% 1px;
  transition: background-size 0.4s ease, color 0.2s ease-in-out;
}
.underline-hover-self:hover {
  background-position: 0% 100%;
  background-size: 100% 1px;
}

.underline-hover:hover .underline-hover__target {
  background-position: 0% 100%;
  background-size: 100% 1px;
}
.underline-hover:hover .underline-hover__target .arts-split-text__line {
  background-position: 0% 100%;
  background-size: 100% 1px;
}

.underline-hover__target:not(.arts-split-text) {
  display: inline-block;
  position: relative;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.4s ease;
}

.gallery {
  margin-top: 2em;
  margin-bottom: 2em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

.blocks-gallery-caption, .wp-block-embed figcaption, .wp-block-image figcaption {
  font-size: 13px;
  text-align: center;
}

.blocks-gallery-item__caption {
  line-height: 1.4;
}

.comments-title, .comment-reply-title {
  position: relative;
  z-index: 50;
  margin-top: 0;
  margin-bottom: 0.25em;
}

.comment-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 0 !important;
}
.comment-list > li {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.comment-list > li ol.children {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 4%;
}
.comment-list > li ol.children li {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.comment-list > li ol.children li:last-child {
  padding-bottom: 0;
}
.comment-list > li:not(:last-child) {
  border-bottom: 1px solid var(--color-border-opacity);
}
.comment-list > ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.comment-author {
  margin-right: 2em;
  flex: 0 0 auto;
}
.comment-author .avatar {
  position: relative;
  width: 100%;
  max-width: 80px;
  max-height: 80px;
  border-radius: 100%;
  display: block;
}

.comment-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.comment-metadata {
  margin-left: 1rem;
}
.comment-metadata a {
  border-bottom: none !important;
}

.comment-body {
  display: flex;
}
.comment-content {
  width: 100%;
}

.reply {
  display: inline-block;
  line-height: 1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.comment-reply-link {
  border-bottom: none !important;
  margin-right: 24px;
}
.comment-reply-link:before {
  content: "\e15e";
  font-family: "Material Icons";
  text-transform: none;
  margin-right: 0.5rem;
}

.comment-respond {
  position: relative;
  z-index: 50;
  margin-top: 1em;
}

.comment-edit-link {
  border-bottom: none !important;
}
.comment-edit-link:before {
  content: "\e3c9";
  font-family: "Material Icons";
  text-transform: none;
  margin-right: 6px;
}

.trackback .edit-link, .pingback .edit-link {
  margin-left: 0.5em;
}

#cancel-comment-reply-link {
  margin-top: 1em;
  margin-left: 1em;
}

.comment {
  outline-width: 1em;
  outline-style: solid;
  outline-color: transparent;
  transition: background-color 2.4s ease, outline-color 2.4s ease, opacity 0.3s ease;
}

.comment_ajax-added {
  outline-color: #eeece6;
  background-color: #eeece6;
}

.comment_ajax-unapproved .comment-body {
  opacity: 0.3;
  pointer-events: none;
}

@media screen and (max-width: 991px) {
  .comment-author {
    margin-right: 1em;
  }
  .comment-author .avatar {
    max-width: 50px;
    max-height: 50px;
  }
  .comment-meta {
    flex-wrap: wrap;
  }
  .comment-metadata {
    margin-top: 0.75em;
    margin-left: 0;
    width: 100%;
  }
}
.post {
  position: relative;
  z-index: 50;
}

.post__wrapper-date {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 50;
}

.post__media, .post__link {
  position: relative;
}

.pagination .nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination .nav-links__container {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: auto;
  margin-right: auto;
}
.pagination .page-numbers {
  --number-size-min: 32;
  --number-size-max: 48;
  width: calc(1 * var(--number-size-min) * 1px);
  height: calc(1 * var(--number-size-min) * 1px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 100%;
  border: 1px solid var(--color-border-opacity);
  transition-property: color, border-color;
  margin: 0.5rem;
}
@media screen and (min-width: 360px) {
  .pagination .page-numbers {
    width: calc(1 * (var(--number-size-min) * 1px + (var(--number-size-max) - var(--number-size-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pagination .page-numbers {
    width: calc(1 * var(--number-size-max) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .pagination .page-numbers {
    height: calc(1 * (var(--number-size-min) * 1px + (var(--number-size-max) - var(--number-size-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .pagination .page-numbers {
    height: calc(1 * var(--number-size-max) * 1px);
  }
}
.pagination .page-numbers.prev {
  font-size: 24px;
  font-weight: normal;
  margin-left: -1rem;
  border-color: transparent;
}
.pagination .page-numbers.next {
  font-size: 24px;
  font-weight: normal;
  margin-right: -1rem;
  border-color: transparent;
}
.pagination .page-numbers:not(a) {
  border-color: transparent;
}
.pagination .page-numbers.current {
  background-color: var(--color-accent);
  color: var(--ui-element-color-dark-theme);
  border-color: transparent;
}
.pagination a.page-numbers:hover {
  border-color: currentColor;
}

.has-cursor-follower .pagination a[data-arts-cursor-follower-target]:hover {
  border-color: transparent !important;
}

@media screen and (max-width: 991px) {
  .pagination .nav-links__container {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .pagination .page-numbers {
    margin: 0.25rem;
  }
  .pagination .page-numbers.prev {
    margin-left: -0.75rem;
  }
  .pagination .page-numbers.next {
    margin-right: -0.75rem;
  }
}
.pagination[data-arts-color-theme=dark] .page-numbers.current, [data-arts-color-theme=dark] .pagination .page-numbers.current {
  color: var(--ui-element-color-light-theme);
}

.post-date-floating {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  --date-size-min: 60;
  --date-size-max: 100;
  width: calc(1 * var(--date-size-min) * 1px);
  height: calc(1 * var(--date-size-min) * 1px);
  text-align: center;
}
@media screen and (min-width: 360px) {
  .post-date-floating {
    width: calc(1 * (var(--date-size-min) * 1px + (var(--date-size-max) - var(--date-size-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .post-date-floating {
    width: calc(1 * var(--date-size-max) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .post-date-floating {
    height: calc(1 * (var(--date-size-min) * 1px + (var(--date-size-max) - var(--date-size-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .post-date-floating {
    height: calc(1 * var(--date-size-max) * 1px);
  }
}

.sidebar > .widget:not(:last-child) {
  margin-bottom: 1.75em;
}

.post-meta {
  list-style-type: none;
  padding: 0;
  margin: 0;
  line-height: 1;
}
.post-meta i {
  font-size: 14px !important;
  vertical-align: middle;
  margin-right: 4px;
}
.post-meta li {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.6;
  vertical-align: middle;
}
.post-meta li span {
  vertical-align: middle;
}
.post-meta li:not(:last-child):after {
  content: "/";
  color: var(--color-accent);
  display: inline-block;
  margin-left: 8px;
  margin-right: 4px;
  transition: all 0.3s ease;
}
.post-meta ul {
  padding-left: 0;
}
.post-meta a {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.post-meta_block li {
  display: block;
  margin: 0.5em 0;
}
.post-meta_block li:not(:last-child):after {
  display: none;
}

.post-meta_flex {
  display: flex;
  justify-content: space-between;
}
.post-meta_flex li:not(:last-child):after {
  display: none;
}

.post-meta_accent * {
  color: var(--color-accent);
}

.section-form__marquee {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: -1;
  pointer-events: none;
}

.section-fixed-wall__wrapper {
  width: 100%;
}
.section-fixed-wall__wrapper.initialized {
  height: 100vh;
  overflow: hidden;
}
.section-fixed-wall__wrapper.initialized .section-fixed-wall__img-wrapper-height {
  display: inline-flex;
  height: 100%;
  width: auto;
}
.section-fixed-wall__wrapper.initialized .section-fixed-wall__img-wrapper-height img, .section-fixed-wall__wrapper.initialized .section-fixed-wall__img-wrapper-height video {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-width: unset;
}

.section-fixed-wall__img-wrapper-height {
  width: 100%;
  height: auto;
}

.section-demo-screens-wall__wrapper-lanes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-demo-screens-wall__overlay {
  z-index: 0;
}

@media screen and (max-width: 991px) {
  .section-demo-screens-wall__overlay {
    --overlay-gradient-opacity-end: 0.75;
  }
}
.tagcloud a, .widget .tagcloud a, .wp-block-tag-cloud a {
  display: inline-block;
  font-size: 13px !important;
  font-weight: 600;
  line-height: 1;
  background-color: transparent;
  padding: 8px 14px;
  margin-bottom: 6px;
  margin-right: 4px;
  border-radius: 100px;
  border: 1px solid var(--color-border-opacity);
  transition: 0.3s ease color, 0.3s ease border-color;
}
.tagcloud a:hover, .widget .tagcloud a:hover, .wp-block-tag-cloud a:hover {
  color: var(--color-accent);
  border-color: currentColor;
}
.tagcloud ul, .widget .tagcloud ul, .wp-block-tag-cloud ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.tagcloud ul li, .widget .tagcloud ul li, .wp-block-tag-cloud ul li {
  display: inline-block;
  margin-bottom: 0;
}

.section-instagram__lane {
  --height-min: 200;
  --height-max: 350;
  --gutters-min: 10;
  --gutters-max: 20;
  height: calc(1 * var(--height-min) * 1px);
}
@media screen and (min-width: 360px) {
  .section-instagram__lane {
    height: calc(1 * (var(--height-min) * 1px + (var(--height-max) - var(--height-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__lane {
    height: calc(1 * var(--height-max) * 1px);
  }
}

.section-instagram__link {
  display: block;
}
.section-instagram__link img {
  height: 100%;
}

.section-instagram__item {
  height: 100%;
}

.section-instagram__link {
  height: 100%;
}

.section-instagram__item-inner {
  padding-left: calc(1 * var(--gutters-min) * 1px);
  padding-right: calc(1 * var(--gutters-min) * 1px);
  height: 100%;
}
@media screen and (min-width: 360px) {
  .section-instagram__item-inner {
    padding-left: calc(1 * (var(--gutters-min) * 1px + (var(--gutters-max) - var(--gutters-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__item-inner {
    padding-left: calc(1 * var(--gutters-max) * 1px);
  }
}
@media screen and (min-width: 360px) {
  .section-instagram__item-inner {
    padding-right: calc(1 * (var(--gutters-min) * 1px + (var(--gutters-max) - var(--gutters-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__item-inner {
    padding-right: calc(1 * var(--gutters-max) * 1px);
  }
}

.section-instagram__inner .section-instagram__lane:first-child {
  padding-bottom: calc(1 * var(--gutters-min) * 1px);
}
@media screen and (min-width: 360px) {
  .section-instagram__inner .section-instagram__lane:first-child {
    padding-bottom: calc(1 * (var(--gutters-min) * 1px + (var(--gutters-max) - var(--gutters-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__inner .section-instagram__lane:first-child {
    padding-bottom: calc(1 * var(--gutters-max) * 1px);
  }
}
.section-instagram__inner .section-instagram__lane:last-child {
  padding-top: calc(1 * var(--gutters-min) * 1px);
}
@media screen and (min-width: 360px) {
  .section-instagram__inner .section-instagram__lane:last-child {
    padding-top: calc(1 * (var(--gutters-min) * 1px + (var(--gutters-max) - var(--gutters-min)) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .section-instagram__inner .section-instagram__lane:last-child {
    padding-top: calc(1 * var(--gutters-max) * 1px);
  }
}

.section-portfolio-grid__col-filter {
  position: relative;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0);
  padding-top: calc(1 * 20 * 1px);
  padding-bottom: calc(1 * 20 * 1px);
}
@media screen and (min-width: 360px) {
  .section-portfolio-grid__col-filter {
    padding-top: calc(1 * (20 * 1px + (40 - 20) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .section-portfolio-grid__col-filter {
    padding-top: calc(1 * 40 * 1px);
  }
}
@media screen and (min-width: 360px) {
  .section-portfolio-grid__col-filter {
    padding-bottom: calc(1 * (20 * 1px + (40 - 20) * (100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .section-portfolio-grid__col-filter {
    padding-bottom: calc(1 * 40 * 1px);
  }
}
.section-portfolio-grid__col-filter.sticking {
  border-color: transparent;
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.04);
}

.section-portfolio-grid_hovered .portfolio-card__link:hover .hover-zoom__zoom {
  transform: scale(0.98);
}
.section-portfolio-grid_hovered .portfolio-card__link:hover .hover-zoom__inner {
  transform: scale(1.02);
}

.section-portfolio-grid_hovered-zoom-out .portfolio-card__link:not(:hover) .hover-zoom__zoom {
  transform: scale(0.95);
}
.section-portfolio-grid_hovered-zoom-out .portfolio-card__link:not(:hover) .hover-zoom__inner {
  transform: scale(1.05);
}
.section-portfolio-grid_hovered-zoom-out .portfolio-card__opacity-link:not(:hover) {
  opacity: 0.5;
}

.section-portfolio-headings-centered__item {
  display: block;
  position: relative;
  z-index: 50;
  pointer-events: none;
}

.section-portfolio-headings-centered__item-header {
  text-align: center;
}

.section-portfolio-headings-centered__item {
  max-width: calc(100% - var(--gutter-horizontal) * 2);
  margin: auto;
}

.section-portfolio-headings-centered__item-media {
  position: relative;
}

.section-portfolio-headings-centered__link {
  display: block;
  pointer-events: auto;
}

.section-portfolio-headings-centered__wrapper-button {
  display: inline-block;
  pointer-events: auto;
}

.section-portfolio-headings-centered__item-counter {
  position: absolute;
  top: 0;
  left: 1em;
  white-space: nowrap;
  pointer-events: none;
  transform: none !important;
}
.section-portfolio-headings-centered__item-counter [data-arts-split-text-element=char]:nth-child(1), .section-portfolio-headings-centered__item-counter .js-arts-split-text__char:nth-child(1) {
  z-index: -1;
  transform: translateY(-40%);
}
.section-portfolio-headings-centered__item-counter [data-arts-split-text-element=char]:nth-child(2), .section-portfolio-headings-centered__item-counter .js-arts-split-text__char:nth-child(2) {
  z-index: 60;
  transform: translateY(-40%);
}

.section-portfolio-headings-centered__item-counter:not([data-arts-split-text-ready=true]) {
  transform: translateY(-40%);
}

.section-portfolio-headings-centered__item-counter_right {
  left: auto;
  right: 1em;
}

@media screen and (max-width: 991px) {
  .section-portfolio-headings-centered__item-header {
    margin-top: 1em;
  }
}
.section-portfolio-headings-centered__item_hovered .hover-zoom__zoom {
  transform: scale(0.98);
}
.section-portfolio-headings-centered__item_hovered .hover-zoom__inner {
  transform: scale(1.02);
}

@media screen and (min-width: 992px) {
  .section-portfolio-headings-centered__item {
    max-width: 100%;
  }
  .section-portfolio-headings-centered__item-header {
    position: absolute;
    top: 50%;
    left: var(--gutter-horizontal);
    right: var(--gutter-horizontal);
    width: auto;
    transform: translateY(-50%);
    margin: auto;
    z-index: 60;
  }
  .section-portfolio-headings-centered__item-media {
    position: relative;
    width: 720px;
    max-width: calc(50vw - var(--gutter-horizontal));
    pointer-events: auto;
  }
}
.section-map {
  height: 100vh;
  max-height: 800px;
}