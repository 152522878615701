.logo
	position: relative
	display: inline-flex
	align-items: center
	vertical-align: middle
a.logo
	&:hover
		//opacity: .8
.logo__wrapper-img
	position: relative
	align-items: center
	flex: 1 0 auto
	img
		+trans2
		display: block
		+fluid('width', var(--logo-min-width), var(--logo-max-width))
.logo__text-title
	display: block
	font-family: var(--font-secondary)
	font-size: 24px
	font-weight: bold
	line-height: 1.3
	color: var(--paragraph-color)
.logo__text-tagline
	display: block
	font-style: italic
	font-size: 12px
	font-weight: bold
	line-height: 1.3
.logo__img-secondary
	position: absolute
	top: 0
	left: 0
	opacity: 0
	visibility: hidden
.logo__description
	margin-top: 1em
