.bg-dark-1
	background-color: var(--color-dark-1) !important
	--overlay-rgb: var(--color-dark-1-rgb) !important
.bg-dark-2
	background-color: var(--color-dark-2) !important
	--overlay-rgb: var(--color-dark-2-rgb) !important
.bg-dark-3
	background-color: var(--color-dark-3) !important
	--overlay-rgb: var(--color-dark-3-rgb) !important
.bg-dark-4
	background-color: var(--color-dark-4) !important
	--overlay-rgb: var(--color-dark-4-rgb) !important

.bg-gray-1
	background-color: var(--color-gray-1) !important
	--overlay-rgb: var(--color-gray-1-rgb) !important
.bg-gray-2
	background-color: var(--color-gray-2) !important
	--overlay-rgb: var(--color-gray-2-rgb) !important
.bg-gray-3
	background-color: var(--color-gray-3) !important
	--overlay-rgb: var(--color-gray-3-rgb) !important
.bg-gray-4
	background-color: var(--color-gray-4) !important
	--overlay-rgb: var(--color-gray-4-rgb) !important

.bg-light-1
	background-color: var(--color-light-1) !important
	--overlay-rgb: var(--color-light-1-rgb) !important
.bg-light-2
	background-color: var(--color-light-2) !important
	--overlay-rgb: var(--color-light-2-rgb) !important
.bg-light-3
	background-color: var(--color-light-3) !important
	--overlay-rgb: var(--color-light-3-rgb) !important
.bg-light-4
	background-color: var(--color-light-4) !important
	--overlay-rgb: var(--color-light-4-rgb) !important

.bg-platinum-1
	background-color: var(--color-platinum-1) !important
	--overlay-rgb: var(--color-platinum-1-rgb) !important
.bg-platinum-2
	background-color: var(--color-platinum-2) !important
	--overlay-rgb: var(--color-platinum-2-rgb) !important

.bg-accent
	background-color: var(--color-accent) !important
	--overlay-rgb: var(--color-accent-rgb) !important
.bg-accent-dark-theme
	background-color: var(--color-accent-dark-theme) !important
	--overlay-rgb: var(--color-accent-dark-theme-rgb) !important
.bg-accent-light-theme
	background-color: var(--color-accent-light-theme) !important
	--overlay-rgb: var(--color-accent-light-theme-rgb) !important
