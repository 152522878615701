// All sides
.p-gutters-vertical
	padding-top: var(--gutter-vertical)
	padding-bottom: var(--gutter-vertical)
	padding-left: var(--gutter-vertical)
	padding-right: var(--gutter-vertical)

// Horizontal
.px-gutters-vertical
	padding-left: var(--gutter-vertical)
	padding-right: var(--gutter-vertical)
.ps-gutter-vertical
	padding-left: var(--gutter-vertical)
.pe-gutter-vertical
	padding-right: var(--gutter-vertical)

// Vertical
.py-gutters-vertical
	padding-top: var(--gutter-vertical)
	padding-bottom: var(--gutter-vertical)
.pt-gutter-vertical
	padding-top: var(--gutter-vertical)
.pb-gutter-vertical
	padding-bottom: var(--gutter-vertical)

// All sides
.m-gutters-vertical
	margin-top: var(--gutter-vertical)
	margin-bottom: var(--gutter-vertical)
	margin-left: var(--gutter-vertical)
	margin-right: var(--gutter-vertical)

// Horizontal
.mx-gutters-vertical
	margin-left: var(--gutter-vertical)
	margin-right: var(--gutter-vertical)
.ms-gutter-vertical
	margin-left: var(--gutter-vertical)
.me-gutter-vertical
	margin-right: var(--gutter-vertical)

// Vertical
.my-gutters-vertical
	margin-top: var(--gutter-vertical)
	margin-bottom: var(--gutter-vertical)
.mt-gutter-vertical
	margin-top: var(--gutter-vertical)
.mb-gutter-vertical
	margin-bottom: var(--gutter-vertical)
