.custom-modal
	--duracion: .25s
	--offset: 0, 50px
	position: fixed
	z-index: 9999
	width: 100vw
	height: 100%
	display: none
	background: var(--color-dark-1)
	//backdrop-filter: blur(6px)
	overflow-y: auto
	.container
		padding: 0px 20px
		display: flex
		min-height: 100%
		img
			@media screen and (max-width: $sm)
				max-width: 260px !important
		.modal-center
			display: flex
			align-items: center	
			width: 100%
		.bg-content-white	
			position: relative
			width: 100%
			+fluid('padding-top', 20, 100)
			+fluid('padding-bottom', 40, 60)




	&.show
		display: block
		animation: show-modal var(--duracion) ease forwards
		section
			animation: reset-translate var(--duracion) ease forwards
		&.hidde
			animation: hidde-modal var(--duracion) ease forwards
			section
				animation: set-translate var(--duracion) ease forwards
	.close-btn
		position: absolute
		font-size: 3rem
		+fluid('right', 20, 30)
		+fluid('top', 20, 30)
		background: none
		border: none
		appearance: none
		color: var(--color-light-1)
		z-index: 99
		cursor: pointer
		@media screen and (max-width: $md)
			font-size: 2.5rem
			
	
@keyframes show-modal 
	from
		opacity: 0
	to
		opacity: 1

@keyframes reset-translate
	from
		transform: translate(var(--offset))
	to
		transform: translate(0)

@keyframes hidde-modal 
	from
		opacity: 1
	to
		opacity: 0



