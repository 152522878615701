// All sides
.p-gutters-horizontal
	padding-top: var(--gutter-horizontal)
	padding-bottom: var(--gutter-horizontal)
	padding-left: var(--gutter-horizontal)
	padding-right: var(--gutter-horizontal)

// Horizontal
.px-gutters-horizontal
	padding-left: var(--gutter-horizontal)
	padding-right: var(--gutter-horizontal)
.ps-gutter-horizontal
	padding-left: var(--gutter-horizontal)
.pe-gutter-horizontal
	padding-right: var(--gutter-horizontal)

// Vertical
.py-gutters-horizontal
	padding-top: var(--gutter-horizontal)
	padding-bottom: var(--gutter-horizontal)
.pt-gutter-horizontal
	padding-top: var(--gutter-horizontal)
.pb-gutter-horizontal
	padding-bottom: var(--gutter-horizontal)

// All sides
.m-gutters-horizontal
	margin-top: var(--gutter-horizontal)
	margin-bottom: var(--gutter-horizontal)
	margin-left: var(--gutter-horizontal)
	margin-right: var(--gutter-horizontal)

// Horizontal
.mx-gutters-horizontal
	margin-left: var(--gutter-horizontal)
	margin-right: var(--gutter-horizontal)
.ms-gutter-horizontal
	margin-left: var(--gutter-horizontal)
.me-gutter-horizontal
	margin-right: var(--gutter-horizontal)

// Vertical
.my-gutters-horizontal
	margin-top: var(--gutter-horizontal)
	margin-bottom: var(--gutter-horizontal)
.mt-gutter-horizontal
	margin-top: var(--gutter-horizontal)
.mb-gutter-horizontal
	margin-bottom: var(--gutter-horizontal)
