.header__arrow-back
	position: relative
	width: 40px
	height: 40px
	display: flex
	flex-direction: column
	justify-content: space-between
.header__arrow-back-line
	position: relative
	width: 32px
	height: 2px
	margin-top: 1px
	margin-bottom: 1px
	margin-left: auto
	margin-right: auto
	overflow: hidden
	clip-path: inset(0px)
	&:nth-of-type(1)
		width: 20px
		transform: rotate(-40deg) translate(-18px, 9px)
		transform-origin: left center
		&:before, &:after
			transition-delay: 50ms
	&:nth-of-type(2)
		margin-top: auto
		margin-bottom: auto
		&:before, &:after
			transition-delay: 0ms
	&:nth-of-type(3)
		width: 20px
		transform: rotate(40deg) translate(-18px, -9px)
		transform-origin: left center
		&:before, &:after
			transition-delay: 50ms
	&:before
		content: ''
		width: 100%
		height: 100%
		position: absolute
		top: 0
		left: 0
		transform: translateX(0%)
		background-color: currentColor
		transition: all 0.3s ease
	&:after
		content: ''
		width: 100%
		height: 100%
		position: absolute
		top: 0
		left: 0
		transform: translateX(calc(-100% - 4px))
		background-color: currentColor
		transition: all 0.5s ease
