
+min-screen-lg
	#nosotros
		margin-top: 0

#nosotros
	.col-top
		position: relative
		+fluid('top', -40, -80)
		+fluid('margin-bottom', -40, -80)
		@media screen and (max-width: $lg)
			top: 0
			margin-bottom: 0

/* CIRCLES */ 

.circle-arrow
	background: var(--color-yellow)
	+fluid('width', 80, 130)
	+fluid('height', 80, 130)
	border-radius: 50%
	display: flex
	justify-content: center
	align-items: center
	svg
		animation: bounce 3s infinite
		+fluid('width', 15, 20)

@keyframes bounce
	0%, 20%, 50%, 80%, 100%
		transform: translateY(0)
	40%
		transform: translateY(15px)
	60%
		transform: translateY(10px)

.circles-wrapper
	.circle
		border-radius: 50%
		border: 2px solid #000
		display: flex
		width: 100%
		justify-content: center
		align-items: center
		text-align: center
		+fluid('padding', 10, 40)
		+trans1
	.hovered
		.circle
			background: var(--color-yellow)
			border-color: var(--color-yellow)
			transform: scale(1.2)
			+trans1
	.circle
		margin-bottom: 1.5vw

+min-screen-md
	.item-1
		margin-top: 10vw
	.item-5
		margin-top: 10vw
	.item-3
		margin-top: 20vw
+max-screen-md
	.circles-wrapper
		max-width: 80vw
		margin: 0 auto
		.item-3
			margin-top: 100px
		.circle
			margin-bottom: 20px
		.col-6:nth-child(3)
			position: relative
			top: -100px

+max-screen-sm
	.circles-wrapper
		max-width: 100%

/* ARROW */ 
.arrow-svg
	+fluid('width', 60, 300)
	+fluid('margin-right', 20, 80)

/* EQUIPO */ 
.equipo
	cursor: pointer
	text-align: center
	transition: transform 0.4s ease
	&.no-link
		pointer-events: none
	&:hover
		transition: transform 0.4s ease
		transform: scale(0.95)

@media (min-width: 992px) and (max-height: 880px)
	.intro
		padding-top: 8vh !important
		h2
			margin-bottom: 10px !important
			font-size: 18px
		.xxl 
			font-size: 17vh
			line-height: 1.1 
	#herramientas
		.xl
			font-size: 16vh
			line-height: 1.15 !important	
	#ayudamos
		padding-top: 17vh !important
		padding-bottom: 18vh !important
		.xl-fix
			font-size: 16vh
	#contacto	
		h1
			font-size: 17vh
		


.scroll
	position: absolute
	top: -110px