.section-fixed-wall__wrapper
	width: 100%
	&.initialized
		height: 100vh
		overflow: hidden
		.section-fixed-wall__img-wrapper-height
			display: inline-flex
			height: 100%
			width: auto
			img, video
				position: relative
				display: inline-flex
				align-items: center
				justify-content: center
				width: auto
				height: 100%
				max-width: unset
.section-fixed-wall__img-wrapper-height
	width: 100%
	height: auto
