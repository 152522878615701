.footer__column-pt-small
	+fluid('padding-top', 30, 30)

.footer__column-pt-medium
	+fluid('padding-top', 80, 140)

.footer__row-pb-small
	+fluid('padding-bottom', 30, 30)

.footer__row-pb-medium
	+fluid('padding-bottom', 80, 140)


