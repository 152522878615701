.z-minus-1
	z-index: -1 !important
.z-100
	z-index: 100 !important
.z-50
	z-index: 50 !important
.z-40
	z-index: 40 !important
.z-10
	z-index: 10 !important
.z-0
	z-index: 0 !important
