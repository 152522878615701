.masthead-1__background
	position: absolute
	top: calc(var(--header-height) + var(--gutter-vertical))
	left: var(--gutter-horizontal)
	right: var(--gutter-horizontal)
	bottom: 0
	height: 100%
	max-width: calc(100% - var(--gutter-horizontal) * 2)
	max-height: calc(100% - var(--header-height) - var(--gutter-vertical) * 2)
.masthead-1__header
	position: relative
	display: flex
	flex-direction: column
	justify-content: space-between
	width: 100%
	height: 100%
	padding-top: calc(var(--header-height) + var(--gutter-vertical) * 2)
	padding-left: var(--gutter-horizontal)
	padding-right: var(--gutter-horizontal)
	padding-bottom: calc(var(--gutter-vertical) * 2)
	margin: 0
	z-index: 50
.masthead-1__heading-top
	display: block
	width: 100%
	max-width: calc(50vw - var(--gutter-horizontal))
	text-align: right
.masthead-1__heading-bottom
	display: block
	width: 100%
	max-width: calc(50vw - var(--gutter-horizontal))
	margin-left: auto
	text-align: left
.masthead-1__description
	width: 100%
	max-width: calc(50% - var(--gutter-horizontal))
	z-index: 60

+max-screen-md
	.masthead-1__header
		justify-content: flex-start
	.masthead-1__heading-top, .masthead-1__heading-bottom
		max-width: 100%
		text-align: left
	.masthead-1__description
		margin-top: auto
		text-align: right
		margin-left: auto
