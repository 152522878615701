.pagination
	.nav-links
		display: flex
		justify-content: space-between
		align-items: center
	.nav-links__container
		margin-top: -0.5rem
		margin-bottom: -0.5rem
		margin-left: auto
		margin-right: auto
	.page-numbers
		--number-size-min: 32
		--number-size-max: 48
		+fluid('width', var(--number-size-min), var(--number-size-max))
		+fluid('height', var(--number-size-min), var(--number-size-max))
		display: inline-flex
		align-items: center
		justify-content: center
		line-height: 1
		border-radius: 100%
		border: 1px solid var(--color-border-opacity)
		transition-property: color, border-color
		margin: 0.5rem
		&.prev
			font-size: 24px
			font-weight: normal
			margin-left: -1rem
			border-color: transparent
		&.next
			font-size: 24px
			font-weight: normal
			margin-right: -1rem
			border-color: transparent
		&:not(a)
			border-color: transparent
		&.current
			background-color: var(--color-accent)
			color: var(--ui-element-color-dark-theme)
			border-color: transparent
	a.page-numbers:hover
		border-color: currentColor

.has-cursor-follower
	.pagination
		a[data-arts-cursor-follower-target]:hover
			border-color: transparent !important

+max-screen-md
	.pagination
		.nav-links__container
			margin-top: -0.25rem
			margin-bottom: -0.25rem
		.page-numbers
			margin: 0.25rem
			&.prev
				margin-left: -0.75rem
			&.next
				margin-right: -0.75rem
