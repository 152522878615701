.section-demo-screens-wall__wrapper-lanes
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
.section-demo-screens-wall__overlay
	z-index: 0

+max-screen-md
	.section-demo-screens-wall__overlay
		--overlay-gradient-opacity-end: 0.75
// 	.section-demo-screens-wall__overlay
// 		background-image: linear-gradient(180deg, rgba(119, 119, 119, 0.5) 0%, #777777 100%)
