.py-half-gutters
	padding-top: max(calc(var(--gutter-vertical) / 2), var(--gutter-vertical-min) * 1px)
	padding-bottom: max(calc(var(--gutter-vertical) / 2), var(--gutter-vertical-min) * 1px)
.px-half-gutters
	padding-left: max(calc(var(--gutter-horizontal) / 2), var(--gutter-horizontal-min) * 1px)
	padding-right: max(calc(var(--gutter-horizontal) / 2), var(--gutter-horizontal-min) * 1px)
.ps-half-gutters
	padding-left: max(calc(var(--gutter-horizontal) / 2), var(--gutter-horizontal-min) * 1px)
.pe-half-gutters
	padding-right: max(calc(var(--gutter-horizontal) / 2), var(--gutter-horizontal-min) * 1px)
