$em-base: 16px
$line-height: 1.8

// Default min/max screen width (for fluid mixins)
$min_width: 360px
$max_width: 1920px

// Responsive Breakpoints
$xxl: 1400px
$xl: 1200px
$lg: 1024px
$md: 991px
$sm: 767px
$xs: 576px
