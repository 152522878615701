.bg-bt
	position: absolute
	left: 0
	right: 0
	top: 0
	height: 1px
	background-color: var(--color-borders)

.bg-br
	position: absolute
	right: 0
	top: 0
	bottom: 0
	width: 1px
	background-color: var(--color-borders)

.bg-bb
	position: absolute
	left: 0
	right: 0
	bottom: 0
	height: 1px
	background-color: var(--color-borders)

.bg-bl
	position: absolute
	left: 0
	top: 0
	bottom: 0
	width: 1px
	background-color: var(--color-borders)
