.tagcloud, .widget .tagcloud, .wp-block-tag-cloud
	a
		display: inline-block
		font-size: 13px !important
		font-weight: 600
		line-height: 1
		background-color: transparent
		padding: 8px 14px
		margin-bottom: 6px
		margin-right: 4px
		border-radius: 100px
		border: 1px solid var(--color-border-opacity)
		transition: 0.3s ease color, 0.3s ease border-color
		&:hover
			color: var(--color-accent)
			border-color: currentColor
	ul
		+reset-list
		li
			display: inline-block
			margin-bottom: 0
