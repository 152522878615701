.js-arts-split-text__wrapper-line
	display: block
	overflow: hidden

.js-arts-split-text__wrapper-char
	display: inline-flex
	overflow: hidden

.js-arts-split-text__wrapper-word
	display: inline-flex
	overflow: hidden
